import { UtilsService } from './utils.service';
import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import moment from 'moment';
import { Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { TrabajoPage } from './trabajo/trabajo.page';
import { HomePage } from './home/home.page';
import { CursosPage } from './cursos/cursos.page';
import { ForosPage } from './foros/foros.page';
import { ComunidadCNCPage } from './comunidad-cnc/comunidad-cnc.page';
import {EarnedComponent} from './badges/earned/earned.component';
import { FaqsPage } from './faqs/faqs.page';
import { PromocionesPage } from './promociones/promociones.page';
import { CodigoDesbloqueoPage } from './soporte/codigoDesbloqueo/codigoDesbloqueo.page';
import { ReparacionPage } from './soporte/reparacion/reparacion.page';
import { InstalacionPage } from './soporte/instalacion/instalacion.page';
import { AsistenciaPage } from './soporte/asistencia/asistencia.page';
import { MaquinascncPage } from './productos/maquinascnc/maquinascnc.page';
import { AccesoriosPage } from './productos/accesorios/accesorios.page';
import { EstudioPage } from './productos/estudio/estudio.page';
import { ProyectoPage } from './productos/proyecto/proyecto.page';
import { ComunityComponent } from './tab-forum/comunity/comunity.component';
import { ServiceService } from './service.service';

declare var UXCam:any;
declare let window: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  now: string;
  token: string;
  idUser: string;
  isMovil:boolean = false;
  isLogged:boolean = false;

  constructor(
    public services: ServiceService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    public utils: UtilsService,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private deeplinks: Deeplinks,
    private navController: NavController
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.isMovil = this.services.isMovil();
    this.isLogged = this.services.isLogged();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if(this.platform.is("ios") || this.platform.is("android")){
        // this.showAlert('Alert',JSON.stringify(cordova),'');
        this.showAppTrackingTransparency();
      } 
      if(this.platform.is("ios")){
        UXCam.optIntoSchematicRecordings() /* To enable session video recording on iOS */ 
        UXCam.startWithKey("2m49hx44q5s2bay")
      }

      this.idUser = localStorage.getItem('idUser');
      this.token = localStorage.getItem('token');

      const today = new Date();
      this.now = moment(today).format('YYYY-MM-DD');

      // tslint:disable-next-line: triple-equals
      this.utils.isLogged = !(this.token == null || this.token == undefined || this.token == '');

      if (this.utils.isLogged) {
        this.router.navigate(['/bienvenido']);
      } else {
        this.router.navigate(['/home']);
      }

      if(this.platform.platforms().indexOf("core") !== -1){
        console.log("Corriendo en un navegado.");
        
      }else{
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }


      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
      //console.log('Notification opened: ' + JSON.stringify(data));  
      console.log(additionalData);
       
      this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });

    // Detección de Url
    this.oneSignal.handleInAppMessageClicked().subscribe(action => {
      let firstClick = action.first_click;
      let closesMessage = action.closes_message;
      let clickUrl = action.click_url;
      //aqui puede mandarse la ruta a visitar
      let clickName = action.click_name;

      //console.log(firstClick);
      //console.log(closesMessage);
      console.log("Nombre de la acción: ");
      console.log(JSON.stringify(action));
      this.router.navigate(["/"+clickName]);
      //console.log(clickName);
      
      
    });

    this.deeplinks.routeWithNavController(this.navController, {
      '/trabajo': TrabajoPage,
      '/home': HomePage,
      '/cursos': CursosPage,
      '/foros': ForosPage,
      '/comunidad-cnc': ComunityComponent,
      '/badges/earned': EarnedComponent,
      '/faqs': FaqsPage,
      '/promociones': PromocionesPage,
      '/soporte/codigoDesbloqueo': CodigoDesbloqueoPage,
      '/soporte/reparacion': ReparacionPage,
      '/soporte/instalacion': InstalacionPage,
      '/soporte/asistencia': AsistenciaPage,
      '/productos/maquinascnc': MaquinascncPage,
      '/productos/accesorios': AccesoriosPage,
      '/productos/estudio': EstudioPage,
      '/productos/proyecto': ProyectoPage
    }).subscribe(match => {
        // match.$route - the route we matched, which is the matched entry from the      arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        console.log('Successfully matched route', match);
      }, nomatch => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);
      });

    
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
    this.oneSignal.endInit();
    /*
    this.deeplinks.route({
      '/trabajo': TrabajoPage, 
    }).subscribe(match => {
      this.router.navigate([match.$link.path]);
    });
    */

  }

  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    })
    alert.present();
  }

  logOut() {
    localStorage.setItem('idUser', '');
    localStorage.setItem('token', '');

    this.utils.isLogged = false;

    this.router.navigate(['/home']);
  }

  backClicked() {
    // 
  }

  private showAppTrackingTransparency() {
    const idfaPlugin = window.cordova.plugins.idfa;
    idfaPlugin.getInfo().then((info) => {
      if (!info.trackingLimited) {
        return info.idfa || info.aaid;
      } else if (
        info.trackingPermission ===
        idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED
      ) {
        return idfaPlugin.requestPermission().then((result) => {
          if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
  
            // Start your tracking plugin here!
  
            return idfaPlugin.getInfo().then((info) => {
              return info.idfa || info.aaid;
            });
          }
        });
      }
    });
  }

  public isActive(base: string): boolean {
        
    return this.router.url.includes(`/${base}`);
  }
}
