import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalPollPageRoutingModule } from './modalpoll-routing.module';
import { ModalPollPage } from './modalpoll.page';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ModalPollPageRoutingModule
  ],
  declarations: [ModalPollPage]
})
export class ModalPollPageModule {}
