import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-modalImages',
  templateUrl: './modalImages.page.html',
  styleUrls: ['./modalImages.page.scss' , '../../assets/scss/styles.scss'],
})
export class ModalImagesPage implements OnInit {

  // Data passed in by componentProps
  @Input() images: any;
  imagesLength: number;
  //
  @Input() current;

  constructor(private modalController: ModalController) {
    this.imagesLength = 0;
  }

  ngOnInit() {
    this.imagesLength = this.images.length;
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }

  next() {
    this.current = (this.current + 1) % this.images.length;
  }
  prev() {
    this.current = (this.current + this.images.length - 1) % this.images.length;
  }

}
