import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ServiceService } from './service.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { NotificacionesPageModule } from './notificaciones/notificaciones.module';
import { ModalPollPageModule } from './modalpoll/modalpoll.module';
import { PostsModalPageModule } from './posts-modal/posts-modal.module';
import { ThreadModalPageModule } from './thread-modal/thread-modal.module';
//import { FormularioPageModule } from './productos/maquinascnc/formulario/formulario.module'; 
import { NotificacionesModalPageModule } from './notificaciones-modal/notificaciones-modal.module';
import { Camera } from '@ionic-native/Camera/ngx';
import { File } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { PhoneService } from './services/phone.service';
import { YouTubePlayerModule } from '@angular/youtube-player';
// import { Metodos } from './tabs/metodos';
import { FooterComponentModule } from './components/footer-component.module';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppVersion } from '@ionic-native/app-version/ngx';
// import { Device } from '@ionic-native/device/ngx';
import { UtilsService } from './utils.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'localstorage',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FooterComponentModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
    exports: [FooterComponentModule],
  providers: [
    StatusBar,
    SplashScreen,
    ServiceService,
    NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    EmailComposer,
    NotificacionesPageModule,
    ModalPollPageModule,
    PostsModalPageModule,
    ThreadModalPageModule,
    SocialSharing,
    //FormularioPageModule,
    NotificacionesModalPageModule,
    Camera,
    File,
    WebView,
    FilePath,
    CallNumber,
    ScreenOrientation,
    StreamingMedia,
    PhoneService,
    Crop,
    // tslint:disable-next-line: deprecation
    YouTubePlayerModule,
    // Metodos
    FirebaseAnalytics,
    Facebook,
    OneSignal,
    AppVersion,
    Deeplinks,
    // Device,
    UtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
