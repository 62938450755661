import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { UserUpdate, CatJob, CatForum } from './actualizar-datos/actualizar-datos.page';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { PointEarnedUser } from './badges/badges-utils';
import { AlertController, NavController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class ServiceService implements OnInit{
  idUser: string;
  mail: string;
  token: string;
  image: any;
  name: string;
  postId:string;

  // Server API
  URL_API = 'https://hitecapi20200702030613.azurewebsites.net/'; // WARNING! PRODUCTION
  // URL_API = 'https://hitecapi20200716202236.azurewebsites.net/'; // QA
  // URL_API = 'https://localhost:44334/'; // LOCAL
  
  // Endpoints
  URL_GET_LOGIN = this.URL_API + 'api/login';
  URL_POST_REGISTER = this.URL_API + 'api/Users';
  URL_POST_ACTIVEACCOUNT = this.URL_API + 'api/Users/ActiveAccount';
  URL_GET_INFO = this.URL_API + 'api/Users/Simple/';
  URL_GET_JOBS = this.URL_API + 'api/WorkOffers';
  URL_GET_USER = this.URL_API + 'api/Users/Update/';
  URL_GET_COURSES = this.URL_API + 'api/Courses/Registered/';
  URL_GET_COURSES_INFO = this.URL_API + 'api/Courses/';
  URL_GET_FORUMS = this.URL_API + 'api/CatForums/Forums';
  URL_GET_CATFORUMS = this.URL_API + 'api/CatForums';
  URL_GET_CATMACHINES = this.URL_API + 'api/CatMachines';
  URL_GET_POSTSBYFORUM = this.URL_API + 'api/Posts/ByForum/';
  URL_GET_POSTSBYUSER = this.URL_API + 'api/Posts/ByUser/';
  URL_GET_POSTSFILTERBYFORUM= this.URL_API + 'api/Filter/ByForum/';
  URL_GET_THREADSBYPOST = this.URL_API + 'api/Threads/ByPost/';
  URL_GET_INSCRIPTION = this.URL_API + 'api/Courses/Inscription';
  URL_POST_POSTS = this.URL_API + 'api/Posts';
  URL_POST_POSTS_IMAGE = this.URL_API + 'api/Posts/Resources';
  URL_POST_THREAD = this.URL_API + 'api/Threads';
  URL_POST_THREAD_IMAGE = this.URL_API + 'api/Threads/Resources/';
  URL_GET_BASIC_USER = this.URL_API + 'api/Users/Basic/';
  URL_PUT_USERSIMPLE = this.URL_API + 'api/Users/Simple/';
  URL_POST_USER = this.URL_API + 'api/Users/';
  URL_GET_USERUPDATE = this.URL_API + 'api/Users/Update/';
  URL_GET_CATJOBS = this.URL_API + 'api/CatJobs';
  URL_PUT_USERPROFILEIMAGE = this.URL_API + 'api/Users/ProfileImage/';
  URL_POST_USERPROFILEIMAGE = this.URL_API + 'api/Users/ProfileImage/';
  URL_DELETE_NOTIFICATION_USER = this.URL_API + 'api/Notifications/byUser/';
  URL_DELETE_NOTIFICATION = this.URL_API + 'api/Notifications/';
  URL_POST_POSTSLIKEUSERS = this.URL_API + 'api/LikesPostsUsers';
  URL_DELETE_POSTSLIKEUSERS = this.URL_API + 'api/LikesPostsUsers/';
  URL_POST_THREADSLIKEUSERS = this.URL_API + 'api/LikesThreadsUsers';
  URL_DELETE_THREADSLIKEUSERS = this.URL_API + 'api/LikesThreadsUsers/';
  URL_GET_WORKOFFERBYID = this.URL_API + 'api/WorkOffers/';
  URL_GET_LIKES = this.URL_API + 'api/Users/Comunity/Likes/';
  URL_POST_NOTIFICATION = this.URL_API + '/api/Notifications';
  URL_GET_POSTCNC: any;
  URL_DELETE_POSTS = this.URL_API + 'api/Posts/';
  URL_BLOCK_USER = this.URL_API + 'api/Users/Block/';
  URL_DELETE_THREAD = `${this.URL_API}api/Threads/`;
  URL_GET_BADGESUSER = this.URL_API + 'api/BadgePoints/GetUsersPointsAsc/';
  URL_GETCOUNTS = this.URL_API + 'api/BadgePoints/GetCounts/';
  URL_PUT_BADGESPOINT = this.URL_API + 'api/BadgePoints';
  URL_PUT_ADDPOINT = this.URL_API + 'api/BadgePoints/AddCounts';
  URL_PUT_ADDPOINTCALCULATOR = this.URL_API + 'api/BadgePoints/AddCountsCalculator';
  URL_PUT_ADD_POINT_APPINTRO = this.URL_API + 'api/BadgePoints/AddPointSectionsApp';
  URL_PUT_ADD_USER_CV = this.URL_API + 'api/BadgePoints/AddUserCv/';
  URL_PUT_ADD_POINT_SHAREOTHERNETWORKS = this.URL_API + 'api/BadgePoints/AddPointSharePost';
  URL_GET_COURSES_CATEGORIES = this.URL_API + 'api/Courses/CategoriesCouses' ;
  URL_GET_COURSES_DETAILS = this.URL_API + 'api/Courses/CoursesByCategories/' ;
  URL_GET_COURSEDETAIL = this.URL_API + 'api/Courses/CourseDetail/';
  URL_GET_PROMOSBYZONE= this.URL_API +'api/Promotions/zone/';
  URL_GET_CAT_TOOLS= this.URL_API +'api/CatTools';
  URL_GET_WORK_OFFERS= this.URL_API +'api/WorkOffers';
  URL_GET_WORK_OFFER_MIX= this.URL_API +'api/CatWorkofferMixes';
  URL_PUT_ADD_POINTS_POST_VACANCY = this.URL_API + 'api/BadgePoints/AddPointsPostVacancy/';
  URL_GET_APP_VERSION = this.URL_API +'api/CatAppVersions/GetVersionApp';
  URL_GET_ADVERTISEMENTS = this.URL_API +'api/advertisement/';
  URL_POST_ADVERTISEMENT_USER_METRIC = this.URL_API+'api/advertisement/AdvertisementUser';
  URL_POST_QUESTIONS_ADVERTISEMENT_USER_METRIC = this.URL_API+'api/advertisement/QuestionAdvertisementUser';
  URL_SAVE_PROMOTIONS_METRICS = this.URL_API+'api/Promotions/PromotionUser';

  constructor(
    public alertController: AlertController,
    private http: HttpClient,
    public storage: Storage,
    private router: Router,
    private navCtrl: NavController,
    private platform:Platform
  ) {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    this.mail = localStorage.getItem("emailsession");
    this.name = localStorage.getItem("completeName");
    this.name = localStorage.getItem("roleId");
  }

  ngOnInit() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    this.mail = localStorage.getItem("emailsession");
    this.name = localStorage.getItem("completeName");
    this.name = localStorage.getItem("roleId");
  }

  private sincrony = new Subject<void>();

  get syncUp() {
    return this.sincrony;
  }

  public login(email, password,appVersion) {
    const datos = { emailHitec: email, passwordHitec: password, appVersion: appVersion };
    // const datos = { email, password };
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return this.http.post(this.URL_GET_LOGIN, datos, options);
  }

  public postPost(detail, forumId, post1) {
    const userId = this.idUser;

    const datos = {
      userId,
      detail,
      forumId,
      post1
    };

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    };

    return this.http.post(this.URL_POST_POSTS, datos, options);
  }

  public postNotifications(notification1, detail, userid, imageUrl) {
    const userId = this.idUser;

    const datos = {
      notification1,
      detail,
      userid,
      imageUrl
    };

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    };

    return this.http.post(this.URL_POST_NOTIFICATION, datos, options);
  }

  public postPostImage(formData) {
    const options = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    };

    return this.http.post(this.URL_POST_POSTS_IMAGE, formData, options);
  }

  public postThread(postId, thread1) {
    const userId = this.idUser;

    const datos = {
      userId,
      postId,
      thread1
    };

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    };

    return this.http.post(this.URL_POST_THREAD, datos, options);
  }

  public postThreadImage(formData) {
    const options = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    };

    return this.http.post(this.URL_POST_THREAD_IMAGE, formData, options);
  }

  public register(obj: any) {

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return this.http.post(this.URL_POST_REGISTER, obj, options).pipe( tap( () => {
      this.sincrony.next();
    }));
  }
  public restaurarPassword(email, password) {
    const datos = { email, password };

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return this.http.post(this.URL_POST_ACTIVEACCOUNT, datos, options).pipe( tap( () => {
      this.sincrony.next();
    }));
  }
  public saveInfoUserService(userData: UserUpdate) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };

    return this.http.put(this.URL_PUT_USERSIMPLE + this.idUser, userData, options).pipe( tap( () => {
      this.sincrony.next();
    }));
  }

  // Disable user
  public desactivarUsuarioService(userData: UserUpdate) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
    return this.http.post(this.URL_POST_USER + this.idUser, userData, options).pipe( tap( () => {
      this.sincrony.next();
    }));
  }

  // Block user
  public blockUserService(id) {
    var active = 2
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
    const data = {
      id,
      active
    };

    return this.http.put(this.URL_BLOCK_USER + id, data, options);
  }

  // Home Function
  public homeUser() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");

    const URL_SERVICE = this.URL_GET_INFO + this.idUser;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(URL_SERVICE, options);
  }

  // Job Exchange Function
  public jobExchange() {
    const URL_SERVICE = this.URL_GET_JOBS;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(URL_SERVICE, options);
  }

  // User Function
  public user(idUser) {
    const URL_SERVICE = this.URL_GET_USER + idUser;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(URL_SERVICE, options);
  }

  public getForums(): Observable<any> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_FORUMS, options).pipe(map(item => item));
    //return this.http.get<any[]>(this.URL_GET_FORUMS, options);
  }

  public getForumsNoObservable(){
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    //return this.http.get(this.URL_GET_FORUMS, options).pipe(map(item => item));
    return this.http.get(this.URL_GET_FORUMS, options);
  }

  public getPostsByForum(idForum,pagina) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_POSTSBYFORUM + idForum + '/' + this.idUser+ '/' + pagina, options).pipe(
      map(results => results)
    );
  }

  public getPostsByUser(idUser,pagina) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_POSTSBYUSER + idUser + '/' + pagina, options).pipe(
      map(results => results)
    );
  }

  // Courses Function
  public courses() {
    const URL_SERVICE = this.URL_GET_COURSES + this.idUser;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(URL_SERVICE, options);
  }

  // Get workoffer by id
  public workOfferById(idWorkOffer) {
    const URL_SERVICE = this.URL_GET_WORKOFFERBYID + idWorkOffer;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(URL_SERVICE, options);
  }

  public getThreadsByPost(idPost,page) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_THREADSBYPOST + idPost + '/' + this.idUser+ '/' + page, options).pipe(
      map(results => results)
    );
  }

  // User for update
  public async userUpdate() {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(this.URL_GET_USERUPDATE + this.idUser, options);
  }

  // Get jobs catalog
  public async getCatJobs() {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get<CatJob[]>(this.URL_GET_CATJOBS, options);
  }

  // Get forums catalog
  public async getCatForums() {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get<CatForum[]>(this.URL_GET_CATFORUMS, options);
  }

  // Get forums catalog
  public async getCatMachines() {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get<CatForum[]>(this.URL_GET_CATMACHINES, options);
  }

  // Basic User
  public basicUser() {
    const URL_SERVICE = this.URL_GET_BASIC_USER + this.idUser;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(URL_SERVICE, options);
  }

  // Courses Details Function
  public coursesDetails(idCourse) {
    const URL_SERVICE = this.URL_GET_COURSES_INFO + idCourse + '/' + this.idUser;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(URL_SERVICE, options);
  }

  // Inscription Function
  public inscription(idcourse) {
    const datos = { userId: this.idUser, catCourseId: idcourse };
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return this.http.put(this.URL_GET_INSCRIPTION, datos, options);
  }

  public deleteNotificationUser() {
    const URL_SERVICE = this.URL_DELETE_NOTIFICATION_USER + this.idUser;

    return this.http.delete(URL_SERVICE).pipe( tap( () => {
      this.sincrony.next();
    }));
  }

  public deleteNotification(idNotification) {
    const URL_SERVICE = this.URL_DELETE_NOTIFICATION + idNotification;

    return this.http.delete(URL_SERVICE);
  }

  public async getAllPostCNC(pagina=0) {
    if(pagina>0){
      this.URL_GET_POSTCNC = this.URL_API + 'api/Users/Comunity/' + this.idUser+'/'+pagina;
    }
    else{
      this.URL_GET_POSTCNC = this.URL_API + 'api/Users/Comunity/' + this.idUser;
    }
    

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_POSTCNC, options).pipe(
      map(results => results)
    );
  }
  public async getFilterPostCNC(strBusca) {
    this.URL_GET_POSTCNC = this.URL_API + 'api/Filter/Comunity/' + this.idUser+'/'+strBusca;
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_POSTCNC, options).pipe(
      map(results => results)
    );
  }
  public async getFilterPostsByForum(idForum,strBusca) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(this.URL_GET_POSTSFILTERBYFORUM + idForum + '/' + this.idUser+ '/' + strBusca, options).pipe(
      map(results => results)
    );
  }

  public async getPopularCNC() {
    this.URL_GET_POSTCNC = this.URL_GET_LIKES + this.idUser;

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_POSTCNC, options).pipe(
      map(results => results)
    );
  }

  public postLikePostsUser(postId) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    const datos = { postId, userId: this.idUser, like: 1 };

    return this.http.post(this.URL_POST_POSTSLIKEUSERS, datos, options).pipe(
      map(results => results)
    );
  }

  public postLikePostsUserDelete(postId) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    const datos = { postId, userId: this.idUser, like: 0 };

    return this.http.delete(this.URL_DELETE_POSTSLIKEUSERS + this.idUser + '/' + postId, options).pipe(
      map(results => results)
    );
  }

  public postLikeThreadsUser(threadId_) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    const datos = { threadId: threadId_, userId: this.idUser };
    return this.http.post(this.URL_POST_THREADSLIKEUSERS, datos, options);
  }

  public postLikeThreadUserDelete(threadId) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.delete(this.URL_DELETE_THREADSLIKEUSERS + this.idUser + '/' + threadId, options);
  }

  public deleteThread(idThread) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.delete(`${this.URL_DELETE_THREAD}${idThread}`, options);
  }


public deletePosts(idPost) {
    const options = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    };
    return this.http.delete(`${this.URL_DELETE_POSTS}${idPost}`, options);
  }

 // manejo de datos dummy seccion publicidad
 public  getPublic() {
   return this.http.get(`../assets/pruebasjson/publicidad.json`);
 }


 public  getJsonOption(item: string) {
   return this.http.get(`../assets/pruebasjson/${item}.json`);
 }


  // Redirect Menu
  redirectCourses() {
    this.router.navigate(['/cursos']);
  }

  redirectCommunity() {
    this.router.navigate(['/tab-forum/comunity']);
  }

  redirectHome() {
    this.router.navigate(['/bienvenido']);
  }

  redirectForums() {
    this.router.navigate(['/tab-forum']);
  }

  redirectWork() {
    // this.router.navigate(['/trabajo']);
    this.router.navigate(['/bags-jobs']);
  }

  redirectVacante() {
    this.router.navigate(['/vacante']);
  }

  redirectTerminos() {
    this.router.navigate(['/terminos']);
  }

  redirectLogin() {
    this.router.navigate(['/home']);
  }

  redirectProducts() {
    this.router.navigate(['/productos']);
  }

  redirectPromotions() {
    this.router.navigate(['/promociones']);
  }

  redirectThreads() {
    this.router.navigate(['/threads', 1]);
  }

  redirectPosts() {
    this.router.navigate(['/posts', 1]);
  }

  redirectPostsByUser() {
    this.router.navigate(['/postsbyuser', 1]);
  }

  redirectProximamente() {
    this.router.navigate(['/proximamente']);
  }

  redirectCalculator(){
    this.router.navigate(['/calculadora']);
  }

  getStorageId() {
    return this.storage.get('id').then((val) => {
      return val;
    });
  }
  getStoragetoken() {
    return this.storage.get('token').then((val) => {
      return val;
    });
  }

  redirectUpdateInfoUser() {
    this.router.navigate(['/actualizar-datos']);
  }

  getUrlPutImagen() {
    return this.URL_PUT_USERPROFILEIMAGE;
  }

  public getElapsedTime(date) {
    const fechaFin = new Date(date);
    // tslint:disable-next-line: indent
    const fechaActual = new Date();
    // Tomar todos los segundos que diferencian con respecto a la fecha final
    // let dif = Math.abs(fechaFin - fechaActual) / 1000;
    const millisBetween = fechaFin.getTime() - fechaActual.getTime();

    let dif = Math.abs(millisBetween) / 1000;

    // calcula y resta los dias
    const days = Math.floor(dif / 86400);
    dif -= days * 86400;

    // calcula y resta las horas
    const hours = Math.floor(dif / 3600) % 24;
    dif -= hours * 3600;

    // calcula y resta los minutos
    const minutes = Math.floor(dif / 60) % 60;
    dif -= minutes * 60;

    // lo que queda son segundos
    const seconds = Math.floor(dif % 60);

    let mensaje = 'Hace un momento.';
    if (days > 0) { mensaje = 'Hace ' + days + (Object.is(days, 1) ? ' día.' : ' días.'); }
    else if (hours > 0) { mensaje = 'Hace ' + hours + (Object.is(hours, 1) ? ' hora.' : ' horas.'); }
    else if (minutes > 0) { mensaje = 'Hace ' + minutes + (Object.is(minutes, 1) ? ' minuto.' : ' minutos.'); }
    else if (seconds > 0) { mensaje = 'Hace un momento.'; }

    return mensaje;
  }

  public async getBadges() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };

    return this.http.get(this.URL_GET_BADGESUSER+this.idUser, options);
  }

  public async getPointsUser() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(this.URL_GETCOUNTS+this.idUser, options);
  }

  public async addPointsBadges(data) {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.put(this.URL_PUT_BADGESPOINT,{...data, IdUser: this.idUser}, options);
  }

  public async addPointCalculator(){
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    
    return this.http.put(this.URL_PUT_ADDPOINTCALCULATOR,{ IdUser: this.idUser }, options).subscribe(
      (data:any)=>{
        if(data.bool1){
          if(data.string1) this.showAlert(data.string1);
          let pointsCalculator:PointEarnedUser = JSON.parse(localStorage.getItem("PointEarnedUser"));
          pointsCalculator.calculationsCount = data.data?.CalculationsCount || 0;
          pointsCalculator.pointsCount = data.data?.PointsCount || 0;
          localStorage.setItem("PointEarnedUser",JSON.stringify(pointsCalculator));
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  async setPointBadges(addPointsBadges:any){
    (await this.addPointsBadges(addPointsBadges)).subscribe(
      (data:any)=>{},
      (error)=>{}
    );
  }

  public async addPointsAppIntro() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    
    return this.http.put(this.URL_PUT_ADD_POINT_APPINTRO,{ IdUser: this.idUser }, options);
  }

  public async addPointsUserCV() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    
    return this.http.put(this.URL_PUT_ADD_USER_CV+this.idUser,{}, options);
  }

  // Para puntos por compatir en redes sociales u otro 
  public async addPointShareSocialNetwork() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    
    //return this.http.get(this.URL_PUT_ADD_POINT_SHAREOTHERNETWORKS,{ userId: this.idUser }).toPromise();
    return this.http.get(this.URL_PUT_ADD_POINT_SHAREOTHERNETWORKS+'/'+this.idUser,{params:  {
      userId: this.idUser
    }, headers: options.headers }).toPromise();
  }

  //Para las categorías de los cursos
  public async getCoursesCategories() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(this.URL_GET_COURSES_CATEGORIES, options).toPromise();
  }

    //Para las categorías de los cursos
    public async getCoursesDetails(id:any) {
      this.idUser = localStorage.getItem("idUser");
      this.token = localStorage.getItem("token");
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        }
      };
      return this.http.get(this.URL_GET_COURSES_DETAILS+id, options).toPromise();
    }

  // Para puntos por compatir en redes sociales u otro 
  public async getDetailCourse(idCourse:number) {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    
    return this.http.get(this.URL_GET_COURSEDETAIL+''+idCourse, options);
  }

  // Para obtener las promociones por zona 
  public async getPromosByZone(idZone:number) {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    
    return this.http.post(this.URL_GET_PROMOSBYZONE, {idZone: idZone, idUser: this.idUser ? this.idUser : 0 }, options);
  }

  // Para obtener cat tool
  public async getCatTools() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(this.URL_GET_CAT_TOOLS, options);
  }

  public async getAppVersion() {

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    return this.http.get(this.URL_GET_APP_VERSION, options).toPromise();
  }

  // Para obtener las ofertas de trabajo
  public async getWorkOffers() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(this.URL_GET_WORK_OFFERS, options);
  }

  // Para obtener datos empresas y universidades
  public async getWorkOfferMix() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    return this.http.get(this.URL_GET_WORK_OFFER_MIX, options);
  }

  // Para obtener datos empresas y universidades
  public async getAdvertisementByScreen(id:number,rowsShows:number=2):Promise<advertisement> {
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    let dataReturn:advertisement = new advertisement();
    let advertisments:any = await this.http.get(this.URL_GET_ADVERTISEMENTS+id, options).toPromise();
    if(advertisments.success){
      dataReturn.Rows = rowsShows;
      advertisments.data.map((ads)=>{
        switch(ads.advertisementTypeId){
          case 1:
            dataReturn.Principal = ads.ads;
            break;
          case 2:
            dataReturn.Medio = ads.ads;
            break;
          case 3:
            dataReturn.Basico = ads.ads;
            break;
        }
      });
      dataReturn.Medio.sort(()=>Math.random()-0.8);
      dataReturn.Basico.sort(()=>Math.random()-0.8);
      dataReturn.MediaBasico = [...dataReturn.Medio,...dataReturn.Basico];
    }
    return dataReturn;
  }

  public async addPointsPostVacancy() {
    this.idUser = localStorage.getItem("idUser");
    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    
    return this.http.put(this.URL_PUT_ADD_POINTS_POST_VACANCY+this.idUser,{}, options);
  }

  public openFrame(url:any){
    if (this.platform.is('ios')) {
      var ifrm = document.createElement('iframe');
      ifrm.setAttribute('id', 'ifrm'); // assign an id
      // to place before another page element
      var el = document.getElementById('iframeHolder');
      el.parentNode.insertBefore(ifrm, el);
      // assign url
      ifrm.setAttribute('src', url);
      ifrm.setAttribute('width', '0px');
      ifrm.setAttribute('height', '0px');
    } else {
      window.open(url, '_system', 'location=yes')
    }
  }

  async showAlert(msg:string){
    const alert = await this.alertController.create({
      message: msg
    });
    await alert.present();
  }

  public goBack(){
    this.navCtrl.back();
  }

  public isMovil(){
    return (this.platform.is('ios') || this.platform.is('android'));
  }

  public isLogged(){
    let token = localStorage.getItem('token');
    return (token) ? true : false;
  }

  public NumberRandom(max:number){
    return Math.floor(Math.random() * (max - 0) + 0);
  }

  // Para guardar relacion de metricas usuario CPM, CPC y MV
  public async postAdvertisementUser(rel_advertisement_metric_ids:any) {
    if(rel_advertisement_metric_ids==null || rel_advertisement_metric_ids.length==0) return;
    let advertisementsUser:any = [];
    rel_advertisement_metric_ids.map(relAdvertisementMetricId=>{
      advertisementsUser.push({ "Id": 0, "RelAdvertisementMetricId": relAdvertisementMetricId, "UserId": Number(this.idUser) });
    });
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    let response = await this.http.post(this.URL_POST_ADVERTISEMENT_USER_METRIC, advertisementsUser, options).toPromise();
    // let response = {};
    return response;
  }

  // Para guardar relacion de metricas usuario CPL
  public async postQuestionAdvertisementUser(data:any) {
    if(data==null) return;
    data.UserId = Number(this.idUser);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    let response = await this.http.post(this.URL_POST_QUESTIONS_ADVERTISEMENT_USER_METRIC, data, options).toPromise();
    // let response = {};
    return response;
  }

  public async savePromotionMetric(promotions:any){
    let promotionSave:Array<any> = [];
    let userId = localStorage.getItem("idUser");
    promotions.map(p=>{
      let metrics = p.metrics.filter(metric => String(metric.metricId) == String(1) || String(metric.metricId) == String(3));
      if(metrics != null && metrics.length > 0)
      {
        promotionSave.push({
          Id: 0,
          RelPromotionMetricId: metrics[0].relAdsMetricsId,
          UserId: userId,
          CreatedAt: null
        })
      }
    });

    this.token = localStorage.getItem("token");
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      }
    };
    let response = await this.http.post(this.URL_SAVE_PROMOTIONS_METRICS, promotionSave, options).toPromise();
    return response;
  }
}
export class advertisement {
  constructor(){}
  SizeDivImage:number = 315;
  SizePage:number = 720;
  Rows:number = 2;
  Random:number = 0;
  Displayed:Array<any> = [];
  Principal:Array<any> = [];
  MediaBasico:Array<any> = [];
  Medio:Array<any> = [];
  Basico:Array<any> = [];
  getAllRelAdsMetricsIdDisplayed = ()=>{
    let adsDisplayed = [];
    let relAdsMetricP = this.Principal[this.Random].metricsAds.find(metric=>metric.metricId==CatMetricTypes.MV);
    if(relAdsMetricP){
      adsDisplayed.push(relAdsMetricP.relAdsMetricsId);
    }
    this.Displayed.map(ad=>{
      let relAdsMetric = ad.metricsAds.find(metric=>metric.metricId==CatMetricTypes.CPM);
      if(relAdsMetric){
        adsDisplayed.push(relAdsMetric.relAdsMetricsId);
      }
    });
    return adsDisplayed;
  };
  getRelAdsMetricsId = (Ads:Array<any>)=>{
    let adsDisplayed = [];
    Ads.map(ad=>{
      let relAdsMetric = ad.metricsAds.find(metric=>metric.metricId==CatMetricTypes.CPM);
      if(relAdsMetric){
        adsDisplayed.push(relAdsMetric.relAdsMetricsId);
      }
    });
    return adsDisplayed;
  }
}
export const CatMetricTypes = {
  MV: 1, // Media view
  CPM: 2, // Costo por millar
  CPC: 3, // Costo por clic
  CPL: 4 // Costo por lead
}