import { NgModule } from '@angular/core';
import { BottombarComponent } from './bottombar/bottombar.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [BottombarComponent],
  imports: [
    IonicModule
  ],
  exports:[BottombarComponent]
})
export class FooterComponentModule { }
