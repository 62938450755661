import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ServiceService } from '../service.service';
import { Storage } from '@ionic/storage';
import { NotificacionesModalPage } from '../notificaciones-modal/notificaciones-modal.page';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.page.html',
  styleUrls: ['./notificaciones.page.scss'],
})
export class NotificacionesPage implements OnInit {

  // Data passed in by componentProps
  @Input() notifications: any;
  notificaciones;
  userid;
  img: any = [ {image_url: '../../assets/images/juan.jpeg'}];

  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    public services: ServiceService,
    public storage: Storage,
    public loadingController: LoadingController
  ) { }

  ngOnInit() {
    //this.getNotifications();
    //console.log(this.notifications);
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  getNotifications(){
    this.notificaciones = this.navParams.get('notifications');
    console.log(this.notificaciones);
  }

  async openModal(notificationid) {
    const modal = await this.viewCtrl.create({
    component: NotificacionesModalPage,
    componentProps: { notificationid }
    });
    modal.onDidDismiss().then(data => {
      console.log(data['data']['notificationid']);
      this.spliceArray(data['data']['notificationid']);
    });
    return await modal.present();
  }

  spliceArray(test){
    let data1 = [test];
    let items = this.notificaciones;
    console.log(items);
    let i = this.notificaciones.length;
    console.log(i);
    while (i--) {
      if (data1.indexOf(items[i].notification) != -1){
          items.splice(i, 1);
      }
    }
    console.log(items);
  }

  public deleteNotification(index, idNotification){
    console.log("public deleteNotification(index, idNotification)");

    this.notifications.splice(index, 1);

    this.services.deleteNotification(idNotification).subscribe(
      data => {
        console.log(data);
      },
      error => {
        console.log(error);
      }
    );
  }

  public async deleteAll(){
    const loading = await this.loadingController.create({
      message: 'Eliminando Notificaciones ...', duration: 3000
    });
    await loading.present();
    
    this.services.deleteNotificationUser().subscribe(
      data => {
        console.log(data);
        //this.viewCtrl.dismiss();
        loading.dismiss()
        this.dismiss();
      },
      error => {
        console.log(error);
      }
    );
  }
  
}
