import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { LoadingController } from '@ionic/angular';
import { ServiceService } from '../service.service';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  isMovil:boolean = false;
  isWeb:boolean = false;
  constructor(
    private fb: Facebook,
    private firebaseAnalytics: FirebaseAnalytics,
    public services : ServiceService,
    private loadingController: LoadingController,
    private router: Router
    ) {
  this.fb.logEvent;
  this.firebaseAnalytics.logEvent;
  this.firebaseAnalytics.setCurrentScreen("Home");
  this.isWeb = !this.services.isMovil();
  }


  public ngOnInit() {
    this.isMovil = this.services.isMovil();
    console.log(this.isMovil);
    this.getPromocionesByZone();
    
  }

  onLoadStart(video:any) {
    this.loading.dismiss();
  }

  promociones:any = [];
  objetoBanner:any = {};
  loading:HTMLIonLoadingElement;
  public async  getPromocionesByZone(){
    this.loading = await this.loadingController.create({
      message: 'Espere por favor ...', duration: 6000
    });
    await this.loading.present();

    let idZone = this.isWeb ? 11 : 10;
    (await this.services.getPromosByZone(idZone)).toPromise()
    .then((response : any ) => {
      if (Array.isArray(response) ) {
        this.promociones = response;
        if(this.promociones.length > 0){
          this.objetoBanner = this.promociones[0];
        }else{
          this.objetoBanner = {};
        }
      }else{
        this.promociones = [];
        this.services.showAlert("Ha ocurrido un error al obtener las promociones.");
      }
    }).catch((error : any ) => {
      this.loading.dismiss();
      this.services.showAlert("Ha ocurrido un error al obtener las promociones.");
    });


  }

  public getFileExtension(fileName: string = "") {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  public goto(url:string){
    this.router.navigate([url], { replaceUrl: true });
  }
}
