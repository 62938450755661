import { AfterContentInit, Component, OnInit } from '@angular/core';
import { BadgesEarned } from '../badges-utils';

@Component({
  selector: 'app-earned',
  templateUrl: './earned.component.html',
  styleUrls: ['./earned.component.scss'],
})
export class EarnedComponent implements OnInit {
  public badgesEarned:Array<BadgesEarned> = [];
  constructor() {}

  ngOnInit(){
    setTimeout(()=>{
      this.badgesEarned = JSON.parse(localStorage.getItem("BadgesEarned"));
      console.log(this.badgesEarned);
    },3000)
  }
}