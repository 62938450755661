import { Injectable } from '@angular/core';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { StreamingVideoOptions, StreamingMedia } from '@ionic-native/streaming-media/ngx';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {


  constructor(
    private callNumber: CallNumber,
    private streamingMedia: StreamingMedia
  ) {
  }

  phoneCall() {
    this.callNumber.callNumber('5541259100', true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }

  autoPlay(url) {
    const options: StreamingVideoOptions = {
      successCallback: () => { console.log('Video played'); },
      errorCallback: (e) => { console.log('Error streaming'); },
      orientation: 'landscape',
      shouldAutoClose: true,
      controls: true
    };

    this.streamingMedia.playVideo(url, options);
  }

}
