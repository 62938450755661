import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'foros',
    loadChildren: () => import('./foros/foros.module').then( m => m.ForosPageModule)
  },
  {
    path: 'bienvenido',
    loadChildren: () => import('./bienvenido/bienvenido.module').then( m => m.BienvenidoPageModule)
  },
  {
    path: 'trabajo',
    loadChildren: () => import('./trabajo/trabajo.module').then( m => m.TrabajoPageModule)
  },
  {
    path: 'vacante',
    loadChildren: () => import('./vacante/vacante.module').then( m => m.VacantePageModule)
  },
  {
    path: 'actualizar-datos',
    loadChildren: () => import('./actualizar-datos/actualizar-datos.module').then( m => m.ActualizarDatosPageModule)
  },
  {
    path: 'cursos',
    loadChildren: () => import('./cursos/cursos.module').then( m => m.CursosPageModule)
  },
  {
    path: 'inscripcion/:id',
    loadChildren: () => import('./inscripcion/inscripcion.module').then( m => m.InscripcionPageModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./productos/productos.module').then( m => m.ProductosPageModule)
  },
  {
    path: 'soporte',
    loadChildren: () => import('./soporte/soporte.module').then( m => m.SoportePageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./faqs/faqs.module').then( m => m.FaqsPageModule)
  },
  {
    path: 'tab-forum',     loadChildren: () => import('./tab-forum/tab-forum.module').then( m => m.TabForumPageModule)
  },
  {
    path: 'threads/:id/:userid/:detail',
    loadChildren: () => import('./threads/threads.module').then( m => m.ThreadsPageModule)
  },
  {
    path: 'posts/:id',
    loadChildren: () => import('./posts/posts.module').then( m => m.PostsPageModule)
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('./notificaciones/notificaciones.module').then( m => m.NotificacionesPageModule)
  },
  {
    path: 'terminos',
    loadChildren: () => import('./terminos/terminos.module').then( m => m.TerminosPageModule)
  },
  {
    path: 'promociones',
    loadChildren: () => import('./promociones/promociones.module').then( m => m.PromocionesPageModule)
  },
  {
    path: 'posts-modal',
    loadChildren: () => import('./posts-modal/posts-modal.module').then( m => m.PostsModalPageModule)
  },
  {
    path: 'thread-modal',
    loadChildren: () => import('./thread-modal/thread-modal.module').then( m => m.ThreadModalPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'modalImages',
    loadChildren: () => import('./modalImages/modalImages.module').then( m => m.ModalImagesPageModule)
  },
  {
    path: 'notificaciones-modal',
    loadChildren: () => import('./notificaciones-modal/notificaciones-modal.module').then( m => m.NotificacionesModalPageModule)
  },
  {
    path: 'revo',
    loadChildren: () => import('./tabs/revolutions/revolutions.module').then( m => m.RevolutionsPageModule)
  },
  {
    path: 'calculadora',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'marche',
   loadChildren: () => import('./tabs/marchuelos/marchuelos.module').then( m => m.MarchuelosPageModule)
  },
  {
    path: 'avan',
    loadChildren: () => import('./tabs/avance/avance.module').then( m => m.AvancePageModule)
  },
  {
    path: 'rpmma',
   loadChildren: () => import('./tabs/rpmmachuelos/rpmmachuelos.module').then( m => m.RpmmachuelosPageModule)
  },
  {
    path: 'brocas',
   loadChildren: () => import('./tabs/brocas/brocas.module').then( m => m.BrocasPageModule)
  },
  {
    path: 'velocity',
    loadChildren: () => import('./tabs/velocity/velocity.module').then( m => m.VelocityPageModule)
  },
  {
    path: 'modalcnc',
    loadChildren: () => import('./modalcnc/modalcnc.module').then( m => m.ModalcncPageModule)
  },
  {
    path: 'modalcalender',
    loadChildren: () => import('./modalcalender/modalcalender.module').then( m => m.ModalcalenderPageModule)
  },
  {
    path: 'detallesvacante/:id',
    loadChildren: () => import('./detallesvacante/detallesvacante.module').then( m => m.DetallesvacantePageModule)
  },
  {
    path: 'torneado',
    loadChildren: () => import('./tabs/torneado/torneado.module').then( m => m.TorneadoPageModule)
  },
  {
    path: 'rpm',
    loadChildren: () => import('./tabs/rpm/rpm.module').then( m => m.RpmPageModule)
  },
  {
    path: 'angulos',
    loadChildren: () => import('./tabs/angulos/angulos.module').then( m => m.AngulosPageModule)
  },
  {
    path: 'proximamente',
    loadChildren: () => import('./proximamente/proximamente.module').then( m => m.ProximamentePageModule)
  },
  {
    path: 'recuperar-password',
    loadChildren: () => import('./recuperar-password/recuperar-password.module').then( m => m.RecuperarPasswordPageModule)
  },
  {
    path: 'postsbyuser/:idUser',
    loadChildren: () => import('./postsbyuser/postsbyuser.module').then( m => m.PostsbyuserPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'badges',
    loadChildren: () => import('./badges/badges.module').then( m => m.BadgesModule)
  },
  {
    path: 'appIntro',
    loadChildren: () => import('./app-intro/app-intro.module').then( m => m.AppIntroPageModule)
  },
  {
    path: 'detallescourses/:id',
    loadChildren: () => import('./detallescourses/detallescourses.module').then( m => m.DetallescoursesPageModule)
  },
  {
    path: 'courseDetail/:id',
    loadChildren: () => import('./course-detail/course-detail.module').then( m => m.CourseDetailPageModule)
  },
  {
    path: 'bags-jobs',
    loadChildren: () => import('./bags-jobs/bags-jobs.module').then( m => m.BagsJobsPageModule)
  },
  {
    path: 'EqvAcabSuperficialesRa',
    loadChildren: () => import('./tabs/equivalencias-acabados-superficiales-ra/equivalencias-acabados-superficiales-ra.module').then( m => m.EquivalenciasAcabadosSuperficialesRaPageModule)
  },
  {
    path: 'TolerIsoDE',
    loadChildren: () => import('./tabs/toler-iso-de/toler-iso-de.module').then( m => m.TolerIsoDEPageModule)
  },
  {
    path: 'TolerIsoDI',
    loadChildren: () => import('./tabs/toler-iso-di/toler-iso-di.module').then( m => m.TolerIsoDIPageModule)
  },
  {
    path: 'RoscAmerEstdTolerP',
    loadChildren: () => import('./tabs/rosc-amer-estd-toler-p/rosc-amer-estd-toler-p.module').then( m => m.RoscAmerEstdTolerPPageModule)
  },
  {
    path: 'TamBrocasFraccMetricoNumAlfa',
    loadChildren: () => import('./tabs/tam-brocas-fracc-metrico-num-alfa/tam-brocas-fracc-metrico-num-alfa.module').then( m => m.TamBrocasFraccMetricoNumAlfaPageModule)
  },
  {
    path: 'BrocasRecomendadasMachuel',
    loadChildren: () => import('./tabs/brocas-recomendadas-machuel/brocas-recomendadas-machuel.module').then( m => m.BrocasRecomendadasMachuelPageModule)
  },
  {
    path: 'BrocasRecmenMachuelearRoscPulg',
    loadChildren: () => import('./tabs/brocas-recmen-machuelear-rosc-pulg/brocas-recmen-machuelear-rosc-pulg.module').then( m => m.BrocasRecmenMachuelearRoscPulgPageModule)
  },
  {
    path: 'tablas',
    loadChildren: () => import('./tabs/tablas/tablas.module').then( m => m.TablasPageModule)
  },
  {
    path: 'tab-forum',
    loadChildren: () => import('./tab-forum/tab-forum.module').then( m => m.TabForumPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
