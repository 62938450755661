import { Component, OnInit} from '@angular/core';
import { ServiceService } from '../service.service';
import { Storage } from '@ionic/storage';
import { AlertController, ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';
import { stringify } from 'querystring';
import { Router } from '@angular/router';
import {
  Camera,
  CameraOptions,
  PictureSourceType,
} from '@ionic-native/Camera/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { finalize } from 'rxjs/operators';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { ChangeDetectorRef } from '@angular/core';
import {
  ActionSheetController,
  ToastController,
  Platform,
  LoadingController,
} from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-modalcnc',
  templateUrl: './modalcnc.page.html',
  styleUrls: ['./modalcnc.page.scss'],
})
export class ModalcncPage implements OnInit {
  postPoint:number = 0;
  badge:number = 0;
  msg:string = '';
  post: string;
  image: any;
  imagenes = [];
  public disabledButton:boolean;
  hashtags:string = "#";
  hashtagsPrev:string = "#";

  token;
  userId;
  forumId;
  detail;
  test: string;
  data;
  imageUrl;
  userName;
  postId: string;
  key:number=0;
  loading;
  isSelected:boolean=false;
  catForums: CatForum[];
  
  constructor(
    public services: ServiceService,
    public storage: Storage,
    public postModal: ModalController,
    private navParams: NavParams,
    private formBuilder: FormBuilder,
    private router: Router,
    private loadingController: LoadingController,
    private platform: Platform,
    private toastController: ToastController,
    private actionSheetController: ActionSheetController,
    private camera: Camera,
    private file: File,
    private filePath: FilePath,
    private webview: WebView,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    public alertController: AlertController
  ) {
    this.getCatForums();
   }

  ngOnInit() {
    if(this.platform.is('desktop') || this.platform.is('electron')){
      this.isSelected= true;
    }else{
      this.isSelected = false;
    }
    this.getUserInfo();
  }

  dismiss() {
    this.postModal.dismiss();
  }

  getUserInfo() {
    this.services.basicUser().subscribe(
      data => {
        console.log(data);
        this.userName = data[0]['name'] + ' ' + data[0]['lastName'];
        this.imageUrl = data[0]['imageUrl'];
      },
      error => {
        console.log(error);
      }
    );
  }
  loadImageFromDevice(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      console.log("reader.result",reader);
      
      // get the blob of the image:
      let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
      // create blobURL, such that we could use it in an image element:
      let blobURL: string = URL.createObjectURL(blob);
      console.log("blobURL",blobURL);
      const newEntry = {
        name: this.createFileName(),
        path: blobURL,
        filePath: blobURL,
        blobImg:blob,
      };
      console.log('imagen nueva:', newEntry);
      this.imagenes = [newEntry, ...this.imagenes];
      event.target.files = null;
    };
    reader.onerror = (error) => {

    };
  }
  async makePost() {
    if (this.post !== undefined && this.post.trim() !== '') {
      if(!this.forumId){
        this.presentToast('Debe Seleccionar un foro.');
        return;
      }
      this.disabledButton = true;
      this.services.postPost(this.post, this.forumId, this.hashtags).subscribe(
        async (data) => {
          this.disabledButton = false;
          let result:any = {... data};
          this.postId = result.postLastId;
          console.log("this.postId:",result);
          this.postPoint = parseInt(result.postPoint);
          if(result.badge==1){
            this.badge = result.badge;
            this.msg = result.msg;
          }

          this.test = JSON.stringify(data);
          if(this.imagenes.length>0){
            this.loading = await this.loadingController.create({
              message: 'Cargando Imagen(es)…'
             });
             await this.loading.present();
             this.key=0;
             await this.imagenes.forEach(async (img,index) => {
              if(this.platform.is('desktop') || this.platform.is('electron')){
                const formData = new FormData();
                formData.append('file', img.blobImg, 'imagen.jpg');
                formData.append('postId', this.postId);
                await this.uploadImageData(formData);
              }else{
                await this.startUpload(img);
              }
            });
          }
          else{
            if(result.badge==1){
              await this.confirm('Sumaste + '+this.postPoint +' puntos'+ ' y '+result.msg);
            }else{
              await this.confirm('Sumaste + '+this.postPoint+" puntos." );
            }
          }

        },
        error => {
          this.disabledButton = false;
          console.log(error);
        },
        ()=>{
          this.disabledButton = false;
        });
    } else {
      this.presentToast('Debe ingresar texto en el campo ¿Que quieres compartir?');
    }
  }

  onKeydown() {
    if(this.getDifference(this.hashtagsPrev,this.hashtags) != " "){
      this.hashtagsPrev = this.hashtags;
      console.log(this.hashtagsPrev);
    }
    else{
      this.hashtags = this.hashtags + "#";
      this.hashtagsPrev = this.hashtags;
    }
  }

  async backspaceFunction(){
    if(this.hashtags == ""){
      this.hashtags = "#";
    }
  }

  getDifference(a, b){
        var i = 0;
        var j = 0;
        var result = "";

        while (j < b.length)
        {
         if (a[i] != b[j] || i == a.length)
             result += b[j];
         else
             i++;
         j++;
        }
        return result;
  }


  // Secction upload image
  async selectImage() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Seleccionar fuente de imagen',
      buttons: [
        {
          text: 'Cargar desde Galeria',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          },
        },
        {
          text: 'Usar Camara',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.CAMERA);
          },
        },
        {
          text: 'Cancelar',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
  }

  async takePicture(sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 100,
      sourceType: sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true,
    };

    await this.camera.getPicture(options).then(async (imagePath) => {
      if (
        this.platform.is('android') &&
        sourceType === this.camera.PictureSourceType.PHOTOLIBRARY
      ) {
        this.filePath.resolveNativePath(imagePath).then(async (filePath) => {
          // tslint:disable-next-line: no-shadowed-variable
          const correctPath = filePath.substr(0, filePath.lastIndexOf('/') + 1);
          // tslint:disable-next-line: no-shadowed-variable
          const currentName = imagePath.substring(
            imagePath.lastIndexOf('/') + 1,
            imagePath.lastIndexOf('?')
          );
          await this.copyFileToLocalDir(
            correctPath,
            currentName,
            this.createFileName()
          );
          console.log(this.imagenes.length);
        });
      } else {
        const currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
        const correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
        await this.copyFileToLocalDir(
          correctPath,
          currentName,
          this.createFileName()
        );
        console.log(this.imagenes.length);
      }
    });
  }

  async copyFileToLocalDir(namePath, currentName, newFileName) {
    await this.file
      .copyFile(namePath, currentName, this.file.dataDirectory, newFileName)
      .then(
        async (success) => {
          await this.uploadImage(newFileName);
        },
        (error) => {
          this.presentToast('Error while storing file.');
        }
      );
  }
  async uploadImage(name) {
    const filePath = (await this.file.dataDirectory) + name;
    const resPath = await this.pathForImage(filePath);

    const newEntry = {
      name: name,
      path: resPath,
      filePath: filePath,
    };
    console.log('imagen nueva:', newEntry);
    this.imagenes = [newEntry, ...this.imagenes];
    await this.ref.detectChanges(); // trigger change detection cycle
  }

  createFileName() {
    // tslint:disable-next-line: one-variable-per-declaration
    const d = new Date(),
      n = d.getTime(),
      newFileName = n + '.jpg';
    return newFileName;
  }
  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
    });
    toast.present();
  }
  async pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      const converted = await this.webview.convertFileSrc(img);
      return converted;
    }
  }
  async startUpload(imgEntry) {
    await this.file
      .resolveLocalFilesystemUrl(imgEntry.filePath)
      .then(async (entry) => {
        // tslint:disable-next-line: no-angle-bracket-type-assertion
        (<FileEntry> entry).file(async (file) => await this.readFile(file));
      })
      .catch((err) => {
        this.presentToast('Error while reading file.');
      });
  }

  async readFile(file: any) {
    const reader = new FileReader();
    reader.onload = async () => {
      const formData = new FormData();
      const imgBlob = await new Blob([reader.result], { type: 'image/jpeg' });
      const id = localStorage.getItem("idUser");

      formData.append('file', imgBlob, 'imagen.jpg');
      formData.append('postId', this.postId);

      await this.uploadImageData(formData);
    };
    reader.readAsArrayBuffer(file);
  }

  async uploadImageData(formData) {
    this.disabledButton = true;
    await this.services.postPostImage(formData).subscribe(
      async (data2) => {
        let result:any = {... data2};
        this.postPoint = this.postPoint + Number(result.postPoint);
        if(result.badge==1){
          this.badge = result.badge;
          this.msg = result.msg;
        }
        this.disabledButton = false;
      },
      async (error) => {
        console.log(error);
        await this.presentToast('Error: ' + error);
        this.disabledButton = false;
      },
      async ()=> {
        this.disabledButton = false;
        if(this.key == (this.imagenes.length-1)){
          await this.loading.dismiss();
          if(this.badge ==1){
            await this.confirm('Sumaste + '+this.postPoint +' puntos'+ ' y '+this.msg);
          }else{
            await this.confirm('Sumaste + '+this.postPoint +' puntos' );
          }
        }
        this.key++;
      });
  }

  async getCatForums() {
    (await this.services.getCatForums()).subscribe(
      (data) => {
        this.catForums = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  async confirm(mensaje) {
    const alert = await this.alertController.create({
      header: '',
      message: mensaje,
      backdropDismiss : false,
      buttons: [
        {
          text: 'Ok',
          handler: async () => {
            console.log("OK - dismiss");
            await this.dismiss();
          }
        }
      ],
    });
    await alert.present();
  }
  deleteImage(imgEntry, position) {
    const imagenesTemp = [...this.imagenes];
    imagenesTemp.splice(position, 1);
    let filtered = imagenesTemp.filter(name => name != imgEntry.name);
      var correctPath = imgEntry.filePath.substr(0, imgEntry.filePath.lastIndexOf('/') + 1);
      this.imagenes = [...filtered];
      this.file.removeFile(correctPath, imgEntry.name).then(res => {
          this.presentToast('Imagen eliminada.');
      });
  }
}

export class CatForum {
  constructor(
    public id?: number,
    public forum?: string,
  ) { }
}
