import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { advertisement, ServiceService } from '../service.service';
import { Storage } from '@ionic/storage';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-trabajo',
  templateUrl: './trabajo.page.html',
  styleUrls: ['./trabajo.page.scss', '../../assets/scss/styles.scss'],
})
export class TrabajoPage implements OnInit {
  img;
  workOffer;
  datos;
  searchbar: string;
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();

  constructor(
    public services: ServiceService,
    public alertController: AlertController,
    public storage: Storage,
    private router: Router,
    private emailComposer: EmailComposer,
    private _location: Location,
    private firebaseAnalytics: FirebaseAnalytics,
    private platform: Platform,
    private oneSignal: OneSignal
    ) {
      this.firebaseAnalytics.logEvent;
      this.firebaseAnalytics.setCurrentScreen("Bolsa de Trabajo");
      this.initializeApp();
    }

  async ngOnInit() {
    this.bringWorkOffers();
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(7,1);
      this.advertisements.SizePage = 355;
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  async scrollingAds(event:any) {
    let rows = Math.floor(Number(event.detail.scrollTop-this.advertisements.SizePage)/this.advertisements.SizeDivImage);
    let final = rows+this.advertisements.Rows;
    if(event.detail.scrollTop > this.advertisements.SizePage && rows>0 && final > this.advertisements.Displayed.length && this.advertisements.Displayed.length < this.advertisements.MediaBasico.length){
      let displayedLength = this.advertisements.Displayed.length;
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,final);
      let disply = this.advertisements.Displayed.slice(displayedLength,this.advertisements.Displayed.length);
      this.services.postAdvertisementUser(this.advertisements.getRelAdsMetricsId(disply));
    }
  }

  backClicked() {
    this._location.back();
  }

  bringWorkOffers() {
    this.services.jobExchange().subscribe(data => {
      this.datos = data;
      console.log(data);
    },
      error => {
        console.log(error);
      },
    );
  }

  async sendUserCv(){
    (await this.services.addPointsUserCV()).subscribe(
      (data:any) => {
        if(data?.win){
          this.showAlert('Obtuvo + 5 puntos.');
          setTimeout(()=>{ this.sendEmail() },3000);
        }else{
          this.sendEmail();
        }
      },
      error => {
        console.log(error);
        this.sendEmail();
      },
    );
  }

  sendEmail() {
    const email = {
      to: 'rhcomuncnc@grupohitec.com',
      // cc: 'erika@mustermann.de',
      // bcc: ['john@doe.com', 'jane@doe.com'],
      attachments: [
        /*
        'file://img/logo.png',
        'res://icon.png',
        'base64:icon.png//iVBORw0KGgoAAAANSUhEUg...',
        'file://README.pdf'
        */
      ],
      subject: 'APLICACIÓN A VACANTE COMUNIDAD CNC',
      body: `<strong>¡BUSCAMOS TALENTO COMO TÚ!</strong><br><br>

      Gracias por tu interés en formar parte de nuestro gran equipo de trabajo, déjanos tus datos y adjunta tu curriculum; en breve nuestro equipo de Atracción de Talento se contactará contigo:<br><br>
      
      Nombre:<br>
      Celular:<br>
      Email:<br>
      Ciudad de residencia:`,
      isHtml: true
    };

    // Send a text message using default options
    this.emailComposer.open(email);
  }

  sendJobId(id){
    this.router.navigate(['/detallesvacante', id]);
  }

  async showAlert(message:string){
    const alert = await this.alertController.create({
      message: message
    });
    await alert.present();
  }

  initializeApp() {
    this.platform.ready().then(()=> {
      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      //this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      //this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
    this.oneSignal.sendTag("user_type","Bolsa de Trabajo");
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
    this.oneSignal.endInit();

  }
}
