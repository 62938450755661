import { Component, OnInit } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { AlertController, LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { Storage } from '@ionic/storage';
import { from, Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { PostsModalPage } from '../posts-modal/posts-modal.page';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@Component({
  selector: 'app-foros',
  templateUrl: './foros.page.html',
  styleUrls: ['./foros.page.scss', '../../assets/scss/styles.scss'],
})
export class ForosPage implements OnInit {
  //listForumAll: Observable<any[]>;
  listForumAll: any;

  searchbar: string;

  loading: any;

  constructor(
    private nativeStorage: NativeStorage,
    public storage: Storage,
    public services: ServiceService,
    public alertController: AlertController,
    private router: Router,
    public modalController: ModalController,
    public loadingController: LoadingController,
    private firebaseAnalytics: FirebaseAnalytics
  ) {
    this.firebaseAnalytics.logEvent;
    this.firebaseAnalytics.setCurrentScreen("Foros");
  }

  ngOnInit() {
    this.loadPage();
  }

  async loadPage() {
    const loading = await this.loadingController.create({
      message: 'Cargando foros ...', duration: 3000
    });
    await loading.present();

    this.services.getForumsNoObservable().subscribe( (res:any) => {
      this.listForumAll = res;
    });
    loading.dismiss();
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: PostsModalPage,
      componentProps: { forumId: this.listForumAll['id'] }
    });
    return await modal.present();
  }

  setForumImage(forum) {
    // console.log("setForumImage()");
    localStorage.setItem("forum",JSON.stringify(forum));
  }
}
