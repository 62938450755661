import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
})
export class BottombarComponent implements OnInit {
  public isMovil:boolean = false;
  constructor(
    public services: ServiceService, 
    private platform: Platform,
    private callNumber: CallNumber,
    private router : Router
   ) {}

  ngOnInit() {
    this.isMovil = this.services.isMovil();
  }

  phone() {
    if(this.isMovil){
      this.callNumber.callNumber('5541259100', true)
        .then(res => console.log('Launched dialer!', res))
        .catch(err => console.log('Error launching dialer', err));
    }
  }

  public isActive(base: string): boolean {
        
    return this.router.url.includes(`/${base}`);
 }

}
