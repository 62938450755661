import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public isLogged: boolean;

  constructor() { }

  /**
   * @description Metodo para el truncado de un valor con decimales
   * @param numToBeTruncated (string) Numero a truncar
   * @param numOfDecimals (number) cantidad de decimales a truncar
   */
  public truncNumber(numToBeTruncated: any, numOfDecimals: any) {
    var theNumber = numToBeTruncated.toString();
    var pointIndex = theNumber.indexOf('.');
    return +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
  }

  /**
 * [solo redondeo de decimales]
 * @param  {number} Valor        [numero a redondear]
 * @param  {number} Precision    [cantidad de deciamles de retorno]
 * @param  {string} TipoRedondeo [tipo ceil|floor|round|trunc]
 * @return {number}              [valor redondeado]
 */
  public roundDecimals(Valor: any, Precision: number = 2, TipoRedondeo: string = 'round') {
    Valor = Number(Valor);
    if (!Number.isNaN(Number(Valor)) && !Number.isNaN(Number(Precision)) && (TipoRedondeo == 'ceil' || TipoRedondeo == 'floor' || TipoRedondeo == 'round' || TipoRedondeo == 'trunc')) {
      let ValorPrecision = 10 ** Number(Precision);
      let ValorXPrecision = Valor * ValorPrecision;
      // let ValueConPrecision = (Number(ValorXPrecision) % 1 == .5) ? Math.floor(ValorXPrecision) : Number(ValorXPrecision).toFixed();
      let RedondeoConPrecision = 0;
      if (TipoRedondeo == 'trunc') {
        RedondeoConPrecision = this.truncateNumber(Valor, Number(Precision));
      }
      else {
        RedondeoConPrecision = Math[TipoRedondeo](ValorXPrecision) / ValorPrecision;
      }
      return Number(RedondeoConPrecision);
    } else {
      return (!Number.isNaN(Number(Valor))) ? Number(Valor.toFixed(2)) : 0;
    }
  };

  /**
 * [trunca un numero a los decimales pasados]
 * @param  {number} numero    [valor a truncar]
 * @param  {number} decimales [cantidad retorno de decimales]
 * @return {number}           [valor truncado]
 */
  public truncateNumber(numero:number, decimales:number){
    try {
      let PartsOfNumber = numero.toString().split('.');
      if (PartsOfNumber && PartsOfNumber.length == 2) {
        let decimalTruncate = PartsOfNumber[1].toString().slice(0, decimales);
        return Number(`${PartsOfNumber[0]}.${decimalTruncate}`);
      }
      return Number(`${numero.toFixed(decimales)}`);
    } catch (error) {
      return 0;
    }
  };

}
