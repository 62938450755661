import { Component, OnInit } from '@angular/core';
import { advertisement, ServiceService } from 'src/app/service.service';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-accesorios',
  templateUrl: './accesorios.page.html',
  styleUrls: ['./accesorios.page.scss' , '../../../assets/scss/styles.scss'],
})
export class AccesoriosPage implements OnInit {
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();
  constructor(
    public services:ServiceService,
    private firebaseAnalytics: FirebaseAnalytics,
    private platform: Platform,
    private oneSignal: OneSignal
    ) {
      this.firebaseAnalytics.logEvent;
    this.firebaseAnalytics.setCurrentScreen("Accesorios y Refacciones");
    this.initializeApp();
   }

  async ngOnInit() {
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(4,1);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  initializeApp() {
    this.platform.ready().then(()=> {
      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      //this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      //this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
    this.oneSignal.sendTag("user_type","Accesorios y Refacciones");
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
    this.oneSignal.endInit();

  }

}
