import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { advertisement, ServiceService } from '../service.service';
import { EmailComposer } from '@ionic-native/email-composer/ngx';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';
//import { url } from 'inspector';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.page.html',
  styleUrls: ['./promociones.page.scss' , '../../assets/scss/styles.scss'],
})
export class PromocionesPage implements OnInit {

  promotionalsBanners:Array<any> = new Array();
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();
  @ViewChild('contentCenter') contentCenter: ElementRef;

  constructor(
    public services:ServiceService,
    private emailComposer: EmailComposer,
    private firebaseAnalytics: FirebaseAnalytics,
    private loadingController: LoadingController,
    private platform: Platform,
    private oneSignal: OneSignal
    ) {
      this.firebaseAnalytics.logEvent;
      this.firebaseAnalytics.setCurrentScreen("Promociones");
      this.initializeApp();
     }

  async ngOnInit() {
    //Llamar el servicio para obtener las promos de la base
    this.getPromosByCategory();
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(4);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows-1);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  async scrollingAds(event:any) {
    let rows = Math.floor(Number(event.detail.scrollTop-this.advertisements.SizePage)/this.advertisements.SizeDivImage);
    let final = rows+this.advertisements.Rows;
    if(event.detail.scrollTop > this.advertisements.SizePage && rows>0 && final > this.advertisements.Displayed.length && this.advertisements.Displayed.length < this.advertisements.MediaBasico.length && event.detail.scrollTop < (this.contentCenter.nativeElement.clientHeight-this.advertisements.SizePage)){
      let displayedLength = this.advertisements.Displayed.length;
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,final);
      let disply = this.advertisements.Displayed.slice(displayedLength,this.advertisements.Displayed.length);
      this.services.postAdvertisementUser(this.advertisements.getRelAdsMetricsId(disply));
    }
  }

  public async getPromosByCategory(){
    /* 
      1	Bienvenido
      2	Promociones
      3	Posts    
    */

   const loading = await this.loadingController.create({
    message: 'Espere por favor ...', duration: 6000
  });

  await loading.present();

   (await this.services.getPromosByZone(2)).subscribe(
     (response : any ) => {
      loading.dismiss();
      if (Array.isArray(response) ) {
        this.promotionalsBanners = response;
      } else {
        //error al obtener los datos
        this.promotionalsBanners = new Array();
        this.services.showAlert("Ha ocurrido un error al obtener las promociones.");
      }
     },(error : any ) => {
      loading.dismiss();
       this.services.showAlert("Ha ocurrido un error al obtener las promociones.");
     });
  }

  public getNameImage(url: any ){
    if(!url) return 'assets/images/imgNotFound.jpeg';
    return String(url) + '?123456789'; 
  }

  public async openEmailDialog(item: any){
    await this.services.savePromotionMetric([item]);
    if(item?.bannerUrl){
      //window.open(item?.bannerUrl, '_system', 'location=yes');
      if(this.isMovil)
        this.openFrame(item?.bannerUrl);
      else if(!this.isMovil)
          window.open(item?.bannerUrl, "_blank");
      return;
    }

    const email = {
      to: String(item.email),
      cc: [],
      attachments: [],
      subject: String(item.mailAffair),
      body: String(item.mailBody),
      isHtml: false,
      app: "correo"
    };
    
    this.emailComposer.open(email);
  }

  public openFrame(url : any ){

    if (this.platform.is('ios')) {
      var ifrm = document.createElement('iframe');
      ifrm.setAttribute('id', 'ifrm'); // assign an id
  
      // to place before another page element
      var el = document.getElementById('iframeHolder');
      el.parentNode.insertBefore(ifrm, el);
  
      // assign url
      ifrm.setAttribute('src', url);
      ifrm.setAttribute('width', '0px');
      ifrm.setAttribute('height', '0px');
    } else {
      window.open(url, '_system', 'location=yes')
    }


  }

  initializeApp() {
    this.platform.ready().then(()=> {
      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      //this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      //this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
    this.oneSignal.sendTag("user_type","Promociones");
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
    this.oneSignal.endInit();

  }

}
