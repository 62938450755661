import { Component, OnInit } from '@angular/core';
import { advertisement, ServiceService } from 'src/app/service.service';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';


@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.page.html',
  styleUrls: ['./asistencia.page.scss' , '../../../assets/scss/styles.scss'],
})
export class AsistenciaPage implements OnInit {
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();
  constructor(
    public services:ServiceService,
    private firebaseAnalytics: FirebaseAnalytics
    ) {
      this.firebaseAnalytics.logEvent;
      this.firebaseAnalytics.setCurrentScreen("Asistencia Técnica Telefónica");
    }
  async ngOnInit() {
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(4,1);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

}
