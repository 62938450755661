import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-notificaciones-modal',
  templateUrl: './notificaciones-modal.page.html',
  styleUrls: ['./notificaciones-modal.page.scss'],
})
export class NotificacionesModalPage implements OnInit {

  notificationid:any;

  constructor(
    public viewCtrl: ModalController,
    public navParams: NavParams,
    public services:ServiceService
  ) { 
    
  }

  ngOnInit() {
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  deleteNotification(){
    this.notificationid = this.navParams.get('notificationid');

    this.services.deleteNotification(this.notificationid).subscribe(
      data => {
        console.log(data);
        this.viewCtrl.dismiss({notificationid : this.notificationid});
      },
      error => {
        console.log(error);
      }
    );

  }

}
