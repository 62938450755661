import { Component, OnInit, Input, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common'; 
import { CatMetricTypes, ServiceService } from '../service.service';

@Component({
  selector: 'app-poll',
  templateUrl: './modalpoll.page.html',
  styleUrls: ['./modalpoll.page.scss' , '../../assets/scss/styles.scss'],
})
export class ModalPollPage implements OnInit {

  @Input() advertisement: any;
  questions: any;
  listAnswers: Array<DataAnswers> = new Array<DataAnswers>();
  qtd:any[] = [];

  constructor(private modalController: ModalController,@Inject(DOCUMENT) document, public services: ServiceService) {}

  ngOnInit() {
    this.questions = this.advertisement.questionsAds;
  }

  async sendAnswers(){
    this.listAnswers = [];
    if(this.getAnswers()){
      let metrica = this.advertisement.metricsAds.find(m=>m.metricId==CatMetricTypes.CPL);
      if(metrica && metrica.relAdsMetricsId){
        let response:any = await this.services.postQuestionAdvertisementUser({ UserId: 0, RelAdvertisementMetricId: metrica.relAdsMetricsId, Answers: this.listAnswers });
        if(response.success){
          this.dismiss();
        }else{
          this.services.showAlert('Verifique las respuestas a enviar');
        }
      }
    }
    else{
      this.services.showAlert('Verifique las respuestas a enviar');
    }    
  }

  getAnswers(){
    let e = false;
    this.questions.forEach((q:any) => {
      if(this.qtd[q.id])
        this.listAnswers.push(<DataAnswers>{ QuestionAdvertisementId:q.id, Answer:this.qtd[q.id], RelAdvertisementsUsersId: 0 }); 
      else{
        e = true;
        return false;  
      }                               
    });

    return !e;  
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}

export class DataAnswers {
  constructor(
    public QuestionAdvertisementId?: number,
    public Answer?: string,
    public RelAdvertisementsUsersId?: number,
  ) { }
}