import { Component, OnInit } from '@angular/core';
import { advertisement, ServiceService } from '../../service.service';
import { EmailComposer } from '@ionic-native/email-composer/ngx';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-reparacion',
  templateUrl: './reparacion.page.html',
  styleUrls: ['./reparacion.page.scss' , '../../../assets/scss/styles.scss'],
})
export class ReparacionPage implements OnInit {
  name: string;
  company: string = '';
  phone: string = '';
  message: string = '';
  email: number;
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();

  constructor(
    public services: ServiceService,
    private emailComposer: EmailComposer,
    private firebaseAnalytics: FirebaseAnalytics,
    private platform: Platform,
    private oneSignal: OneSignal
    ) {
      this.firebaseAnalytics.logEvent;
      this.firebaseAnalytics.setCurrentScreen("Reparación y Mantenimiento");
      this.initializeApp();
    }

  async ngOnInit() {
    this.name = localStorage.getItem("completeName");
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(4,1);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  public sendEmail() {
    let emailTo;

    switch(this.email) {
      case 1:
        emailTo = 'serviciotecnico@nexttec.com.mx';
      break;
      case 2:
        emailTo = 'serviciotecnico@haas.com.mx';
      break;
      case 3:
        emailTo = 'serviciotecnico@hitec.com.mx';
      break;
    }
    
    let cuerpo = 
      'Nombre: ' + this.name + '\n' + 
      'Teléfono: ' + this.phone + '\n' + 
      'Empresa: ' + this.company + '\n' +
      'Mensaje: ' + this.message + '\n';

    const email = {
      to: emailTo,
      cc: [],
      attachments: [],
      subject: 'Cotización',
      body: cuerpo,
      isHtml: false,
      app: 'correo'
    };

    // console.log(email);

    this.emailComposer.open(email);
  }

  initializeApp() {
    this.platform.ready().then(()=> {
      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      //this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      //this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
    this.oneSignal.sendTag("user_type","Reparación y mantenimiento");
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
    this.oneSignal.endInit();

  }
}
