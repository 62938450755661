import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { advertisement, CatMetricTypes, ServiceService } from '../../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, LoadingController, AlertController, IonInfiniteScroll, Platform } from '@ionic/angular';
import { ModalcncPage } from '../../modalcnc/modalcnc.page';
import { ModalImagesPage } from '../../modalImages/modalImages.page';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastController } from '@ionic/angular';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ThreadModalPage } from '../../thread-modal/thread-modal.page';
import { PostsModalPage } from '../../posts-modal/posts-modal.page';

@Component({
  selector: 'app-comunity',
  templateUrl: './comunity.component.html',
  styleUrls: ['./comunity.component.scss', '../../../assets/scss/styles.scss'],
})
export class ComunityComponent implements OnInit, AfterViewChecked {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  DEFAULT_PAGE = 1;
  iduser;
  imageUrl: string;
  dataPostsCNCTemp: any;
  dataPostsCNC: any;
  lengthListPublic: number;
  listaPublicacion: any = [];
  postsComunidad: [];
  frameUrl = '../../assets/images/star.svg';
  follows: any = [];
  searchbar: string;
  detailString: string;
  roleId: number;
  actualPublicidadIndex: any;
  friendCode: any;
  last_index: Number = 0;
  disabledRefresher: boolean;
  isWeb: boolean = false;
  isSharing:boolean = false;
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();
  @ViewChild('contentCenter') contentCenter: ElementRef;

  constructor(
    public services: ServiceService,
    private router: Router,
    public modalController: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    public sanitizer: DomSanitizer,
    public toastController: ToastController,
    private firebaseAnalytics: FirebaseAnalytics,
    private socialSharing: SocialSharing,
    private emailComposer: EmailComposer,
    private platform: Platform,
    private oneSignal: OneSignal
  ) {
    const urls = this.router.url;
    JSON.stringify(localStorage.setItem('urls', urls.toString()));
    this.iduser = JSON.parse(localStorage.getItem('idUser'));
    this.roleId = JSON.parse(localStorage.getItem('roleId'));
    this.firebaseAnalytics.logEvent;
    this.firebaseAnalytics.setCurrentScreen("Comunidad CNC");
    this.actualPublicidadIndex = 0;
    this.initializeApp();
    this.isWeb = !this.services.isMovil();
  }

  /***
   * 
   * Pruebas de Social Sharing
   */

  text: string = 'Flamenco'
  imgurl: string = 'https://cdn.pixabay.com/photo/2019/12/26/05/10/pink-4719682_960_720.jpg'
  link: string = 'https://link.medium.com/JA4amAHFJ5'

  ShareGeneric(post: any) {
    const url = this.link;
    console.log(post);
    //const text = parameter+'\n'

    if(this.isSharing == true) return;
    this.isSharing = true;
    var arrayUrls: Array<string> = new Array();
    if (post.postResources.length > 0) {
      for (let index = 0; index < post.postResources.length; index++) {
        const img_post = post.postResources[index];
        arrayUrls.push(String(img_post.resourceUrl).toString());

      }

    }
    console.log(arrayUrls);

    let options = {
      message: String(post.detail).concat(this.getSharedString()), // not supported on some apps (Facebook, Instagram)
      subject: String(post.post), // fi. for email
      files: arrayUrls, // an array of filenames either locally or remotely
      url: '',
      chooserTitle: 'Compartir en' // Android only, you can override the default share sheet title
    }

    console.log(options);
    this.socialSharing.shareWithOptions(options).then(async (result) => {
      console.log("Share completed? ", result.completed); // On Android apps mostly return false even while it's true
      console.log("Shared to app: ", result.app); // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      //this.services.showAlert('Compartido correctamente: '+result.completed);
      const loading = await this.loadingController.create({
        message: 'Cargando', duration: 4000
      });
      await loading.present();
      this.onSharedPost();
      await loading.dismiss();
    }, (err) => {
      console.log("Sharing failed with message: ", err);
      this.services.showAlert('Ha ocurrido el siguiente error: ' + err);
    }).finally(() => {
      this.isSharing = false;
    });


  }

  shareViaCorreoWeb(post: any) {
    var mail = document.createElement("a");
    mail.href = "mailto:tucorreoaenviar@dominio?subject=" + String(post.post) + "&body= " + encodeURIComponent(String(post.detail).concat(this.getSharedString()).concat(this.getResourcesImages(post?.postResources)));
    mail.click();
  }

  getResourcesImages(resources: any): string {
    if (Array.isArray(resources) && resources.length == 0) return "";
    if (!Array.isArray(resources)) return "";
    console.log(resources);

    let links: string = "\n\nImagenes adjuntas: \n\n";
    for (let index = 0; index < resources.length; index++) {
      const element = resources[index];
      //links.concat(String(element?.resourceUrl)).concat("\n");
      links += String(element?.resourceUrl) + "\n";
    }
    console.log(links);
    links += "\n\n";
    return links;
  }

  shareViaWhatsApp(post: any) {

  }


  ngAfterViewChecked() {
    //console.log("Ejecutado: ngAfterViewChecked()" );

  }

  public getSharedString() {
    return "\n\nNo te pierdas más contenido como éste en la app oficial de HiTec ¡Descargala! No olvides registrarte con mi código de invitación: " + this.friendCode + "\n ¡Aquí la puedes descargar!:\n http://comunidadcnc.com/descarga.html";
  }

  public onSharedPost() {
    //Cuando ya se compartió a otra app
    //this.loadingController.
    this.services.addPointShareSocialNetwork()
      .then((response: any) => {
        console.log(response);
        if (response != null) {
          this.services.showAlert(response.msg);
          /*
          if(response.hasOwnProperty('msg')){
            this.services.showAlert(response.msg);
          }
          */
        } else {
          this.services.showAlert('Ha ocurrido un error al sumar los puntos obtenidos.');
        }
      }).catch((error: any) => {
        this.services.showAlert('Ha ocurrido un error al sumar los puntos obtenidos. ');
        //this.services.showAlert(JSON.stringify(error));
      })
  }


  ShareWhatsapp() {
    this.socialSharing.shareViaWhatsApp(this.text, this.imgurl, this.link)
  }

  ShareFacebook() {
    this.socialSharing.shareViaFacebookWithPasteMessageHint(this.text, this.imgurl, null /* url */, 'Copia Pega!')
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      //this.showAlert(title, msg, additionalData.task);
    });

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;

      //this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
    this.oneSignal.sendTag("user_type", "Comunidad CNC");
    this.oneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });
    this.oneSignal.endInit();

  }



  /**
   * Fin de Social Sharing
   */

  async ngOnInit() {
    //this.listPublic();
    //this.actualPublicidadIndex = 0;
    //this.last_index = 5;
    //this.getPromosByCategory();
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(5);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  async scrollingAds(event:any) {
    let rows = Math.floor(Number(event.detail.scrollTop-this.advertisements.SizePage)/this.advertisements.SizeDivImage);
    let final = rows+this.advertisements.Rows;
    if(event.detail.scrollTop > this.advertisements.SizePage && rows>0 && final > this.advertisements.Displayed.length && this.advertisements.Displayed.length < this.advertisements.MediaBasico.length && event.detail.scrollTop < (this.contentCenter.nativeElement.clientHeight-this.advertisements.SizePage)){
      let displayedLength = this.advertisements.Displayed.length;
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,final);
      let disply = this.advertisements.Displayed.slice(displayedLength,this.advertisements.Displayed.length);
      this.services.postAdvertisementUser(this.advertisements.getRelAdsMetricsId(disply));
    }
  }

  async ionViewWillEnter() {
    this.actualPublicidadIndex = 0;

    const loading = await this.loadingController.create({
      message: 'Cargando Posts', duration: 3000
    });
    await this.getPromosByCategory();
    await loading.present();
    this.disabledRefresher = false;
    await this.getAllPostCNC(null);
    await loading.dismiss();
  }

  async doRefresh(event) {
    this.searchbar = "";
    this.dataPostsCNC.posts = [];
    this.DEFAULT_PAGE = 1;
    await this.getAllPostCNCDoRefresh(event);
  }

  async getAllPostCNC(event) {
    this.disabledRefresher = false;
    (await this.services.getAllPostCNC(this.DEFAULT_PAGE)).subscribe(
      async (data: any) => {
        
        this.dataPostsCNCTemp = { ...data };
        this.follows = this.dataPostsCNCTemp?.usersByPointsDesc;
        this.friendCode = data?.friendCode;
        if (this.dataPostsCNC === undefined) {
          this.dataPostsCNC = { ...data };

          this.dataPostsCNC.posts = [];
        } else
          if (this.DEFAULT_PAGE == 1) {
            this.dataPostsCNC = { ...data };

            this.dataPostsCNC.posts = [];
          }
        this.dataPostsCNC.posts = [...this.dataPostsCNC.posts, ...this.dataPostsCNCTemp.posts];
        this.actualPublicidadIndex = 0;
        this.dataPostsCNC.posts = this.dataPostsCNC.posts.map((item, index) => ({
          ...item,
          truncateString: (item.detail.length > 250 ? true : false),
          showMore: false,
          isBannerVisible: index % 4 == 0 ? true : false,
          dataPublicidad: this.getInfoBanner(index+1)
        }));
        console.log(this.dataPostsCNC.posts);
        if (event != null) {
          await event.target.complete();
          if (this.dataPostsCNCTemp.posts.length < 10) {
            event.target.disabled = true;
          }

        }
        this.DEFAULT_PAGE++;
      },
      (error) => {
        console.log(error);
      }, (() => {
        //this.renderImagePromo(1);
      })
    );
  }
  trimString(string, length) {
    return string.length > length ?
      string.substring(0, length) + '...' :
      string;
  }
  async getAllPostCNCDoRefresh(event) {
    (await this.services.getAllPostCNC(this.DEFAULT_PAGE)).subscribe(
      async (data) => {
        this.dataPostsCNCTemp = { ...data };

        if (this.dataPostsCNC === undefined) {
          this.dataPostsCNC = { ...data };
          this.dataPostsCNC.posts = [];
        } else
          if (this.DEFAULT_PAGE == 1) {
            this.dataPostsCNC = { ...data };
            this.dataPostsCNC.posts = [];
          }
        this.dataPostsCNC.posts = [...this.dataPostsCNC.posts, ...this.dataPostsCNCTemp.posts]
        this.dataPostsCNC.posts = this.dataPostsCNC.posts.map(item => ({
          ...item,
          truncateString: (item.detail.length > 250 ? true : false),
          showMore: false,
        }));
        await event.target.complete();
        await this.toggleInfiniteScroll();
        this.DEFAULT_PAGE++;
        this.last_index = 0;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async toggleInfiniteScroll() {
    this.infiniteScroll.disabled = await !this.infiniteScroll.disabled;
  }

  async getPopularCNC() {
    const loading = await this.loadingController.create({
      message: 'Cargando Posts', duration: 3000
    });
    await loading.present();

    (await this.services.getPopularCNC()).subscribe(
      async (data) => {
        this.dataPostsCNC = data;
        await loading.dismiss();
      },
      (error) => {
        console.log(error);
      }
    );

  }

  async search(q: string) {
    if (q == "") {
      await this.cancelSearch();
    }
    else {
      (await this.services.getFilterPostCNC(q)).subscribe(
        async (data) => {
          this.dataPostsCNC = { ...data };
          await this.toggleInfiniteScroll();
          // await loading.dismiss();

        },
        async (error) => {
          console.log(error);
          // await loading.dismiss();
        }
      );
    }
  }

  async clearSearch(e) {
    await this.cancelSearch();
  }

  async cancelSearch() {
    /*const loading = await this.loadingController.create({
      message: 'Cargando Posts', duration: 3000
    });
    await loading.present();*/
    this.searchbar = "";
    this.dataPostsCNC.posts = [];
    this.DEFAULT_PAGE = 1;
    await this.getAllPostCNC(null);
    // await loading.dismiss();
    this.infiniteScroll.disabled = false;
  }

  getElapsedTime(date) {
    return this.services.getElapsedTime(date);
  }

  routerForo() {
    this.router.navigate(['/foros']);
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: ModalcncPage,cssClass:(!this.services.isMovil())?'custom-modal':''
    });

    modal.onDidDismiss().then(async data => {
      await this.loadPosts();
    });

    return await modal.present();
  }

  async loadPosts() {
    this.dataPostsCNC.posts = [];
    this.DEFAULT_PAGE = 1;
    const loading = await this.loadingController.create({
      message: 'Cargando Posts', duration: 3000
    });
    await loading.present();
    await this.getAllPostCNC(null);
    await loading.dismiss();
  }

  async openModalImages(images, current) {
    const modal = await this.modalController.create({
      component: ModalImagesPage,
      cssClass: 'my-custom-class',
      componentProps: {
        'images': images,
        'current': current
      }
    });
    return await modal.present();
  }

  async likePostUser(post: any, index) {
    this.dataPostsCNC.posts[index].likesPostsUsersCount++;
    // tslint:disable-next-line: no-unused-expression
    this.dataPostsCNC.posts[index].likesPostsUser == true;

    this.services.postLikePostsUser(post.id).subscribe(
      async (data) => {
        const response = data;
        this.dataPostsCNC.posts[index].likesPostsUsers = response[0].likesPostsUsers;
        this.dataPostsCNC.posts[index].likesPostsUsersCount = response[0].likesPostsUsersCount;
        this.dataPostsCNC.posts[index].likesPostsUser = true;
        this.postNotificationLike('notification1', post.detail, post.user.id);
        this.postNotificationLikePointsEarned(post.user.id, response[0].pointsEarned.pointsWin);
      },
      (error) => {
        console.log(error);
        if (this.dataPostsCNC.posts[index].likesPostsUsersCount > 0) {
          this.dataPostsCNC.posts[index].likesPostsUsersCount--;
          this.dataPostsCNC.posts[index].likesPostsUser = false;
        }
      }
    );
  }

  // Envio de notificación al usuario al hacer un like
  async postNotificationLike(notification1, details, userid) {
    this.detailString = details;
    this.imageUrl = localStorage.getItem("imageUrl");
    if (this.detailString.length > 25) {
      this.detailString = localStorage.getItem("completeName") + ' le dió like a "' + this.detailString.substr(0, 25) + '..."';
    }
    else {
      this.detailString = localStorage.getItem("completeName") + ' le dió like a "' + details + '"';
    }
    (await this.services.postNotifications(notification1, this.detailString, userid, this.imageUrl)).subscribe(
      async (data) => { },
      (error) => {
        console.log(error);
      }
    );

  }

  async postNotificationLikePointsEarned(userid, points = 0) {
    if (points > 0) {
      this.imageUrl = localStorage.getItem("imageUrl");
      (await this.services.postNotifications('notification1', 'Sumaste ' + points + ' puntos por like recibidos.', userid, this.imageUrl)).subscribe(
        async (data) => { },
        (error) => { }
      );
    }
  }

  async likePostUserDelete(postId, index) {
    if (this.dataPostsCNC.posts[index].likesPostsUsersCount > 0) {
      this.dataPostsCNC.posts[index].likesPostsUsersCount--;
      this.dataPostsCNC.posts[index].likesPostsUser = false;
    }

    await this.services.postLikePostsUserDelete(postId).subscribe(
      async (data) => {
        const post = data;

        this.dataPostsCNC.posts[index].likesPostsUsers = post[0].likesPostsUsers;
        this.dataPostsCNC.posts[index].likesPostsUsersCount = post[0].likesPostsUsersCount;
        this.dataPostsCNC.posts[index].likesPostsUser = false;
      },
      (error) => {
        console.log(error);
        this.dataPostsCNC.posts[index].likesPostsUsersCount++;
        this.dataPostsCNC.posts[index].likesPostsUser = true;
      }
    );
  }

  async deletePost(postId, index) {
    const alert = await this.alertController.create({
      message: 'Si borras un post con respuestas de otros participantes, sus puntos se te restarán a ti ¿Seguro de eliminar este posts?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            this.services.deletePosts(postId).subscribe((res) => {
              this.dataPostsCNC.posts.splice(index, 1);
            }, (error) => {
              if(error.error) this.services.showAlert(error.error);
            });
          }
        }
      ]
    });
    await alert.present();
  }

  async blockUser(userId) {
    const alert = await this.alertController.create({
      message: '¿Seguro que quieres bloquear a este usuario?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            this.services.blockUserService(userId).subscribe((res) => {
              console.log("El perro ha sido bloqueado");
            }, error => console.error(error));
          }
        }
      ]
    });
    await alert.present();
  }

  async presentToastWithOptions(postId, index, userId) {
    const alert = await this.alertController.create({
      message: 'Elegir una acción a relizar',
      buttons: [
        {
          text: 'Borrar Post',
          handler: () => {
            this.deletePost(postId, index);
            //toast.dismiss();
          }
        }, {
          text: 'Bloquear Usuario',
          handler: () => {
            this.blockUser(userId);
            //toast.dismiss();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Favorite clicked');
          }
        }
      ]
    });
    
    await alert.present();
  }

  async listPublic() {
    await this.services.getPublic().subscribe((res) => {
      this.listaPublicacion = res;
      this.lengthListPublic = Object.keys(res).length;
    });
  }

  public async getPromosByCategory() {
    /* 
      1	Bienvenido
      2	Promociones
      3	Posts    
    */

    const loading = await this.loadingController.create({
      message: 'Espere por favor ...', duration: 6000
    });

    await loading.present();

    (await this.services.getPromosByZone(3)).subscribe(
      (response: any) => {
        loading.dismiss();
        if (Array.isArray(response)) {
          this.listaPublicacion = response;
          this.lengthListPublic = Object.keys(response).length;
          //console.log(this.listaPublicacion);

        } else {
          //error al obtener los datos
          this.listaPublicacion = new Array();
          this.services.showAlert("Ha ocurrido un error al obtener las promociones.");
        }
      }, (error: any) => {
        loading.dismiss();
        this.services.showAlert("Ha ocurrido un error al obtener las promociones.");
      });
  }

  public getNameImage(index_: any) {

    if (!Array.isArray(this.listaPublicacion) || this.listaPublicacion.length == 0) {
      this.actualPublicidadIndex = 0;
      return 'assets/images/imgNotFound.jpeg';
    } else {


      let url = this.listaPublicacion[this.actualPublicidadIndex]?.resourceUrl;
      console.log('ItemPublicidadIndex getNameImage(): ', this.actualPublicidadIndex);
      console.log('LastIndex: ', this.last_index, ':', 'New index: ', index_);

      if (Number(this.last_index) != Number(index_)) {

        this.actualPublicidadIndex = this.actualPublicidadIndex == this.listaPublicacion.length - 1 ? 0 : this.actualPublicidadIndex + 1;
        this.last_index = index_;
        return String(url) + '?123456789';
      } else {
        //this.last_index = index_;
        if (!url) return 'assets/images/imgNotFound.jpeg';
        return String(url) + '?123456789';
      }


    }


  }

  public getInfoBanner(indexPost:number) {
    if (!Array.isArray(this.listaPublicacion) || this.listaPublicacion.length == 0) {
      this.actualPublicidadIndex = 0;
      return null;
    } else if(indexPost%5 == 0) {
      let objeto = this.listaPublicacion[this.actualPublicidadIndex];
      this.actualPublicidadIndex = this.actualPublicidadIndex == this.listaPublicacion.length - 1 ? 0 : this.actualPublicidadIndex + 1;
      return objeto;
    }else{
      return null;
    }
  }

  public renderImagePromo(index_: any) {
    console.log("Rendering image");
    this.last_index = 0;
    if (!Array.isArray(this.listaPublicacion) || this.listaPublicacion.length == 0) {
      //this.actualPublicidadIndex = 0;
      //return 'assets/images/imgNotFound.jpeg';
    } else {


      let url = this.listaPublicacion[this.actualPublicidadIndex]?.resourceUrl;

      if (Number(this.last_index) != Number(index_)) {

        this.actualPublicidadIndex = this.actualPublicidadIndex == this.listaPublicacion.length - 1 ? 0 : this.actualPublicidadIndex + 1;
        this.last_index = index_;
        //var img = document.createElement('img');
        var img = new Image();
        img.src = !url ? 'assets/images/imgNotFound.jpeg' : url;
        if (document.getElementById('spanBanner')) document.getElementById('spanBanner').appendChild(img);
        //img.click = this.openEmailDialog();
        //return String(url) + '?123456789';
      } else {
        //this.last_index = index_;
        //if(!url) return 'assets/images/imgNotFound.jpeg';
        //return String(url) + '?123456789';
      }


    }



  }

  async threads(postId, userId, detail) {
    this.router.navigate(['/threads', postId, userId, detail]);
  }

  postsByUser(idUserFromPost) {
    // console.log("postsByUser(idUserFromPost)");
    // console.log(idUserFromPost);

    this.router.navigate(['/postsbyuser', idUserFromPost]);
  }


  public async openEmailDialog(item_: any) {
    let item: any = new Object();
    item = item_;
    await this.services.savePromotionMetric([item]);
    if (item?.bannerUrl) {
      //window.open(item?.bannerUrl, '_system', 'location=yes');
      if(this.isMovil)
        this.openFrame(item?.bannerUrl);
      else if(!this.isMovil)
        window.open(item?.bannerUrl, "_blank");
      return;
    }

    const email = {
      to: String(item.email),
      cc: [],
      attachments: [],
      subject: String(item.mailAffair),
      body: String(item.mailBody),
      isHtml: false,
      app: "correo"
    };

    this.emailComposer.open(email);
  }

  public openFrame(url: any) {

    if (this.platform.is('ios')) {
      var ifrm = document.createElement('iframe');
      ifrm.setAttribute('id', 'ifrm'); // assign an id

      // to place before another page element
      var el = document.getElementById('iframeHolder');
      el.parentNode.insertBefore(ifrm, el);

      // assign url
      ifrm.setAttribute('src', url);
      ifrm.setAttribute('width', '0px');
      ifrm.setAttribute('height', '0px');
    } else {
      window.open(url, '_system', 'location=yes')
    }
  }

}
