import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PostsModalPageRoutingModule } from './posts-modal-routing.module';

import { PostsModalPage } from './posts-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PostsModalPageRoutingModule
  ],
  declarations: [PostsModalPage]
})
export class PostsModalPageModule {}
