import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Storage } from '@ionic/storage';
import { AlertController, ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import {
  Camera,
  CameraOptions,
  PictureSourceType,
} from '@ionic-native/Camera/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { ChangeDetectorRef } from '@angular/core';
import {
  ActionSheetController,
  ToastController,
  Platform,
  LoadingController,
} from '@ionic/angular';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-thread-modal',
  templateUrl: './thread-modal.page.html',
  styleUrls: ['./thread-modal.page.scss'],
})
export class ThreadModalPage implements OnInit {
  public disabledButton:boolean;
  post: string;
  badge:number=0;
  msg:string="";
  threadPoint:number=0;
  imagenes = [];
  token;
  userid;
  detail;
  postid;
  threadId:string;
  userName;
  userId;
  imageUrl;
  loading;
  key;
  detailString:string;
  imageLink: string;
  isSelected:boolean=false;
  constructor(
    public services: ServiceService,
    public storage: Storage,
    public threadModal: ModalController,
    private navParams: NavParams,
    private platform: Platform,
    private toastController: ToastController,
    private actionSheetController: ActionSheetController,
    private camera: Camera,
    private file: File,
    private filePath: FilePath,
    private webview: WebView,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private loadingController: LoadingController,
    public alertController: AlertController,
  ) { }

  ngOnInit() {
    if(this.platform.is('desktop') || this.platform.is('electron')){
      this.isSelected= true;
    }else{
      this.isSelected = false;
    }
    this.getUserInfo();
  }

  dismiss() {
    this.threadModal.dismiss();
  }

  getUserInfo() {
    this.storage.get('token').then((val) => {
      this.token = val;
      console.log(this.token);

      this.services.basicUser().subscribe(
        data => {
          console.log(data);
          this.userName = data[0]['name'] + ' ' + data[0]['lastName'];
          this.imageUrl = data[0]['imageUrl'];
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  postNotificationComment(){
    this.detailString =  this.navParams.get('detail');
    this.userId = this.navParams.get('userId');
    this.imageLink = localStorage.getItem("imageUrl");
    if(this.detailString.length > 25){
      this.detailString = localStorage.getItem("completeName") + ' comentó en "' + this.detailString.substr(0, 25) + '..."';
    }
    else{
      this.detailString = localStorage.getItem("completeName") + ' comentó en "' + this.detailString + '"';
    }
    ( this.services.postNotifications(this.navParams.get('notification1'), this.detailString, this.userId, this.imageLink)).subscribe(
      async (data) => {
      },
      (error) => {
        console.log(error);
      }
    );
    
  }

  async makePost() {
    if(this.post == undefined || this.post.trim() == '') {
      this.presentToast('Debe ingresar texto en el campo "Escribe tu mensaje aquí"');
      return;
    }
    this.token = await this.storage.get('token');
    this.userid = await this.storage.get('id');
    this.postid = await  this.navParams.get('postId');
    this.disabledButton = true;
    this.services.postThread(this.postid, this.post).subscribe(
      async (data) => {
        this.disabledButton = false;
        this.postNotificationComment()
        let result:any = {... data};
        this.threadId = result.threadId;
          this.threadPoint = parseInt(result.threadPoint);
          if(result.badge==1){
            this.badge = result.badge;
            this.msg = result.msg;
          }

        if( this.imagenes.length>0){
          this.loading = await this.loadingController.create({
            message: 'Cargando Imagen(es)…'
           });
           await this.loading.present();
           this.key=0;
           await this.imagenes.forEach(async (img,index) => {
            if(this.platform.is('desktop') || this.platform.is('electron')){
              const formData = new FormData();
              formData.append('file', img.blobImg, 'imagen.jpg');
              formData.append('ThreadId', this.threadId);
              await this.uploadImageData(formData);
            }else{
              await this.startUpload(img);
            }
            });
        }
        else{
          if(result.badge==1){
            await this.confirm('Sumaste + '+this.threadPoint +' puntos'+ ' y '+result.msg);
          }else{
            await this.confirm('Sumaste + '+this.threadPoint +' puntos' );
          }
        }
        
      },
      error => {
        this.disabledButton = false;
        console.log(error);
        if(error instanceof HttpErrorResponse){
          this.confirm(error.error);
        }
      });
  }
  loadImageFromDevice(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      console.log("reader.result",reader);
      
      // get the blob of the image:
      let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
      // create blobURL, such that we could use it in an image element:
      let blobURL: string = URL.createObjectURL(blob);
      console.log("blobURL",blobURL);
      const newEntry = {
        name: this.createFileName(),
        path: blobURL,
        filePath: blobURL,
        blobImg:blob,
      };
      console.log('imagen nueva:', newEntry);
      this.imagenes = [newEntry, ...this.imagenes];
      event.target.files = null;
    };
    reader.onerror = (error) => {

    };
  };
  async confirm(mensaje) {
    const alert = await this.alertController.create({
      header: '',
      message: mensaje,

      buttons: [
        {
          text: 'Ok',
          handler: async () => {
            console.log("OK - dismiss");
            await this.dismiss();
          }
        }
      ],
      backdropDismiss : false
    });

    await alert.present();
  }
  // Secction upload image
  async selectImage() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Seleccionar fuente de imagen',
      buttons: [
        {
          text: 'Cargar desde Galeria',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          },
        },
        {
          text: 'Usar Camara',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.CAMERA);
          },
        },
        {
          text: 'Cancelar',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
  }
  async takePicture(sourceType: PictureSourceType) {
    var options: CameraOptions = {
      quality: 100,
      sourceType: sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true,
    };

    await this.camera.getPicture(options).then(async (imagePath) => {
      if (
        this.platform.is('android') &&
        sourceType === this.camera.PictureSourceType.PHOTOLIBRARY
      ) {
        this.filePath.resolveNativePath(imagePath).then(async (filePath) => {
          let correctPath = filePath.substr(0, filePath.lastIndexOf('/') + 1);
          let currentName = imagePath.substring(
            imagePath.lastIndexOf('/') + 1,
            imagePath.lastIndexOf('?')
          );
          await this.copyFileToLocalDir(
            correctPath,
            currentName,
            this.createFileName()
          );
          console.log(this.imagenes.length);
        });
      } else {
        var currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
        var correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
        await this.copyFileToLocalDir(
          correctPath,
          currentName,
          this.createFileName()
        );
        console.log(this.imagenes.length);
      }
    });
  }
  async copyFileToLocalDir(namePath, currentName, newFileName) {
    await this.file
      .copyFile(namePath, currentName, this.file.dataDirectory, newFileName)
      .then(
        async (success) => {
          await this.uploadImage(newFileName);
        },
        (error) => {
          this.presentToast('Error while storing file.');
        }
      );
  }
  async uploadImage(name) {
    let filePath = (await this.file.dataDirectory) + name;
    let resPath = await this.pathForImage(filePath);

    let newEntry = {
      name: name,
      path: resPath,
      filePath: filePath,
    };
    console.log('imagen nueva:', newEntry);
    this.imagenes = [newEntry, ...this.imagenes];
    await this.ref.detectChanges(); // trigger change detection cycle
  }

  createFileName() {
    var d = new Date(),
      n = d.getTime(),
      newFileName = n + '.jpg';
    return newFileName;
  }
  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
    });
    toast.present();
  }
  async pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      let converted = await this.webview.convertFileSrc(img);
      return converted;
    }
  }
  async startUpload(imgEntry) {
    this.disabledButton = true;
    await this.file
      .resolveLocalFilesystemUrl(imgEntry.filePath)
      .then(async (entry) => {
        this.disabledButton = false;
        (<FileEntry>entry).file(async (file) => await this.readFile(file));
      })
      .catch((err) => {
        this.disabledButton = false;
        this.presentToast('Error while reading file.');
      });
  }

  async readFile(file: any) {
    const reader = new FileReader();
    reader.onload = async () => {
      
      const formData = new FormData();
      const imgBlob = await new Blob([reader.result], { type: 'image/jpeg' });
      formData.append('file', imgBlob, 'imagen.jpg');
      formData.append('ThreadId', this.threadId);

      await this.uploadImageData(formData);
    };
    reader.readAsArrayBuffer(file);
  }

  async uploadImageData(formData) {
    console.log("formData",formData);
    await this.services.postThreadImage(formData).subscribe(
      async (data2) => {
        let result:any = {... data2};
        console.log(data2);

        this.threadPoint = this.threadPoint + Number(result.threadPoint);
        if(result.badge==1){
          this.badge = result.badge;
          this.msg = result.msg;
        }
      },
      async (error) => {
        console.log(error);
        await this.presentToast('Error: ' + error);
      },
      async ()=> {
        if(this.key == (this.imagenes.length-1)){
          await this.loading.dismiss();
          if(this.badge ==1){
            await this.confirm('Sumaste + '+this.threadPoint +' puntos'+ ' y '+this.msg);
          }else{
            await this.confirm('Sumaste + '+this.threadPoint +' puntos' );
          }
        }
        this.key++;
      });
  }

  deleteImage(imgEntry, position) {
    const imagenesTemp = [...this.imagenes];
    imagenesTemp.splice(position, 1);
    let filtered = imagenesTemp.filter(name => name != imgEntry.name);
      var correctPath = imgEntry.filePath.substr(0, imgEntry.filePath.lastIndexOf('/') + 1);
      this.imagenes = [...filtered];
      this.file.removeFile(correctPath, imgEntry.name).then(res => {
          this.presentToast('Imagen eliminada.');
      });
  }
}
