import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { advertisement, ServiceService } from '../service.service';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.page.html',
  styleUrls: ['./faqs.page.scss' , '../../assets/scss/styles.scss'],
})

export class FaqsPage implements OnInit {

  /* ARRAY THAT WILL CONTAINS ALL FAQS INFORMATION */
  information: any[];

  automaticClose = false;
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();
  @ViewChild('contentCenter') contentCenter: ElementRef;

  constructor(private http: HttpClient, 
    public services:ServiceService,
    private firebaseAnalytics: FirebaseAnalytics,
    private platform: Platform,
    private oneSignal: OneSignal
    ) {
    this.http.get('assets/faqs.json').subscribe(res => {
      this.information = res['items'];/*GET THE ARRAY INSIDE OF JSON FILE*/
      //this.information[0].open = true;
      this.firebaseAnalytics.logEvent;
      this.firebaseAnalytics.setCurrentScreen("FAQ");
    });
    this.initializeApp();
   }

  async ngOnInit() {
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(4);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  async scrollingAds(event:any) {
    let rows = Math.floor(Number(event.detail.scrollTop-this.advertisements.SizePage)/this.advertisements.SizeDivImage);
    let final = rows+this.advertisements.Rows;
    if(event.detail.scrollTop > this.advertisements.SizePage && rows>0 && final > this.advertisements.Displayed.length && this.advertisements.Displayed.length < this.advertisements.MediaBasico.length && event.detail.scrollTop < (this.contentCenter.nativeElement.clientHeight-this.advertisements.SizePage)){
      let displayedLength = this.advertisements.Displayed.length;
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,final);
      let disply = this.advertisements.Displayed.slice(displayedLength,this.advertisements.Displayed.length);
      this.services.postAdvertisementUser(this.advertisements.getRelAdsMetricsId(disply));
    }
  }

  toggleSelection(index){
    this.information[index].open = !this.information[index].open;
    if(this.automaticClose && this.information[index].open){
      this.information
      .filter((item,itemIndex) => itemIndex != index)
      .map(item => item.open = false);
    }
  }

  toggleItem(index,childIndex){
    this.information[index].children[childIndex].open = !this.information[index].open;
  }

  initializeApp() {
    this.platform.ready().then(()=> {
      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      //this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      //this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
    this.oneSignal.sendTag("user_type","FAQ");
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
    this.oneSignal.endInit();

  }

}
