import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Storage } from '@ionic/storage';
import { AlertController, ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {
  Camera,
  CameraOptions,
  PictureSourceType,
} from '@ionic-native/Camera/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { ChangeDetectorRef } from '@angular/core';
import {
  ActionSheetController,
  ToastController,
  Platform,
  LoadingController,
} from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-posts-modal',
  templateUrl: './posts-modal.page.html',
  styleUrls: ['./posts-modal.page.scss'],
})
export class PostsModalPage implements OnInit {
  postPoint:number = 0;
  badge:number = 0;
  msg:string = '';
  post:string;
  image: any;
  imagenes = [];
  hashtags = "#";
  hashtagsPrev = "#";
  token;
  userid;
  forumid;
  detail;
  test:any;
  data;
  imageUrl;
  userName;
  postId:string;
  key:number=0;
  loading:any;
  isSelected:boolean=false;
  disabledButton:boolean = false;
  constructor(
    public services:ServiceService,
    public storage: Storage,
    public postModal: ModalController,
    private navParams: NavParams,
    private formBuilder: FormBuilder,
    private router: Router,
    private loadingController: LoadingController,
    private platform: Platform,
    private toastController: ToastController,
    private actionSheetController: ActionSheetController,
    private camera: Camera,
    private file: File,
    private filePath: FilePath,
    private webview: WebView,
    private ref: ChangeDetectorRef,
    public alertController: AlertController,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    if(this.platform.is('desktop') || this.platform.is('electron')){
      this.isSelected= true;
    }else{
      this.isSelected = false;
    }
    this.getUserInfo();
  }

  async dismiss() {
    await this.postModal.dismiss();
  }

  getUserInfo(){
      this.services.basicUser().subscribe(
        data => {
          this.userName = data[0]['name'] + ' ' + data[0]['lastName'];
          this.imageUrl = data[0]['imageUrl'];
        },
          error => {
          console.log(error);
        }
      );
  }

  onKeydown() {
    if(this.getDifference(this.hashtagsPrev,this.hashtags) != " "){
      this.hashtagsPrev = this.hashtags;
      console.log(this.hashtagsPrev);
    }
    else{
      this.hashtags = this.hashtags + "#";
      this.hashtagsPrev = this.hashtags;
    }
  }

  getDifference(a, b){
    var i = 0;
    var j = 0;
    var result = "";

    while (j < b.length)
    {
     if (a[i] != b[j] || i == a.length)
         result += b[j];
     else
         i++;
     j++;
    }
    return result;
}

  async makePost(){
    this.forumid = await this.navParams.get('forumId');
    if (this.post !== undefined && this.post.trim() !== '') {
      this.disabledButton = true;
      await this.services.postPost(this.post, this.forumid, this.hashtags).subscribe(
        async (data) => {
          this.disabledButton = false;
          let result:any = {... data};
          this.postId = result.postLastId;
          console.log("this.postId:",result);
          this.postPoint = parseInt(result.postPoint);
          if(result.badge==1){
            this.badge = result.badge;
            this.msg = result.msg;
          }
        this.test = JSON.stringify(data);
        
        if(this.imagenes.length>0){
          this.loading = await this.loadingController.create({
            message: 'Cargando Imagen(es)…'
           });
           await this.loading.present();
           this.key=0;
           await this.imagenes.forEach(async (img,index) => {
              if(this.platform.is('desktop') || this.platform.is('electron')){
                const formData = new FormData();
                formData.append('file', img.blobImg, 'imagen.jpg');
                formData.append('postId', this.postId);
                await this.uploadImageData(formData);
              }else{
                await this.startUpload(img);
              }
            });
            
        }
        else{
          if(result.badge==1){
            await this.confirm('Sumaste + '+this.postPoint +' puntos'+ ' y '+result.msg);
          }else{
            await this.confirm('Sumaste + '+this.postPoint +' puntos' );
          }
          
        }
      },
      error => {
        this.disabledButton = false;
        console.log(error);
      },
      ()=>{
        this.disabledButton = false;
      });
    } else {
      this.presentToast('Debe ingresar texto en el campo ¿Que quieres compartir?');
    }
  }
  async confirm(mensaje) {
    const alert = await this.alertController.create({
      header: '',
      message: mensaje,

      buttons: [
        {
          text: 'Ok',
          handler: async () => {
            console.log("OK - dismiss");
            await this.dismiss();
          }
        }
      ],
      backdropDismiss : false
    });

    await alert.present();
  }
  loadImageFromDevice(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      console.log("reader.result",reader);
      
      // get the blob of the image:
      let blob: Blob = new Blob([new Uint8Array((reader.result as ArrayBuffer))]);
      // create blobURL, such that we could use it in an image element:
      let blobURL: string = URL.createObjectURL(blob);
      console.log("blobURL",blobURL);
      const newEntry = {
        name: this.createFileName(),
        path: blobURL,
        filePath: blobURL,
        blobImg:blob,
      };
      console.log('imagen nueva:', newEntry);
      this.imagenes = [newEntry, ...this.imagenes];
      event.target.files = null;
    };
    reader.onerror = (error) => {

    };
  };
   // Secction upload image
   async selectImage() {
     console.log("this.platform",this.platform);
    const actionSheet = await this.actionSheetController.create({
      header: 'Seleccionar fuente de imagen',
      buttons: [
        {
          text: 'Cargar desde Galeria',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          },
        },
        {
          text: 'Usar Camara',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.CAMERA);
          },
        },
        {
          text: 'Cancelar',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
  }
  async takePicture(sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 100,
      sourceType: sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true,
    };

    await this.camera.getPicture(options).then(async (imagePath) => {
      if (
        this.platform.is('android') &&
        sourceType === this.camera.PictureSourceType.PHOTOLIBRARY
      ) {
        this.filePath.resolveNativePath(imagePath).then(async (filePath) => {
          let correctPath = filePath.substr(0, filePath.lastIndexOf('/') + 1);
          let currentName = imagePath.substring(
            imagePath.lastIndexOf('/') + 1,
            imagePath.lastIndexOf('?')
          );
          await this.copyFileToLocalDir(
            correctPath,
            currentName,
            this.createFileName()
          );
          console.log(this.imagenes.length);
        });
      } else {
        var currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
        var correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
        await this.copyFileToLocalDir(
          correctPath,
          currentName,
          this.createFileName()
        );
        // console.log(this.imagenes.length);
      }
    });
  }

  async copyFileToLocalDir(namePath, currentName, newFileName) {
    await this.file
      .copyFile(namePath, currentName, this.file.dataDirectory, newFileName)
      .then(
        async (success) => {
          await this.uploadImage(newFileName);
        },
        (error) => {
          this.presentToast('Error while storing file.');
        }
      );
  }
  async uploadImage(name) {
    let filePath = (await this.file.dataDirectory) + name;
    let resPath = await this.pathForImage(filePath);

    const newEntry = {
      name: name,
      path: resPath,
      filePath: filePath,
    };
    console.log('imagen nueva:', newEntry);
    this.imagenes = [newEntry, ...this.imagenes];
    await this.ref.detectChanges(); // trigger change detection cycle
  }

  createFileName() {
    // tslint:disable-next-line: one-variable-per-declaration
    const d = new Date(),
      n = d.getTime(),
      newFileName = n + '.jpg';
    return newFileName;
  }
  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000,
    });
    toast.present();
  }
  async pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      const converted = await this.webview.convertFileSrc(img);
      return converted;
    }
  }
  async startUpload(imgEntry) {
    await this.file
      .resolveLocalFilesystemUrl(imgEntry.filePath)
      .then(async (entry) => {
        (<FileEntry> entry).file(async (file) => await this.readFile(file));
      })
      .catch((err) => {
        this.presentToast('Error while reading file.');
      });
  }

  async readFile(file: any) {
    const reader = new FileReader();
    reader.onload = async () => {
      const formData = new FormData();
      const imgBlob = await new Blob([reader.result], { type: 'image/jpeg' });
      var id = await this.services.getStorageId();
      formData.append('file', imgBlob, 'imagen.jpg');
      formData.append('postId', this.postId);
      await this.uploadImageData(formData);
    };
    reader.readAsArrayBuffer(file);
  }

  async uploadImageData(formData) {
    console.log("formData: ",formData);
    
    await this.services.postPostImage(formData).subscribe(
      async (data2:any) => {
        let result:any = await {... data2};
        console.log(data2);

        this.postPoint = await this.postPoint + Number(result.postPoint);
        if(result.badge==1){
          this.badge =await result.badge;
          this.msg = await result.msg;
        }
       
    },
    async (error) => {
      console.log(error);
      await this.presentToast('Error: ' + error);
    },
    async ()=> {
      if(this.key == (this.imagenes.length-1)){
        await this.loading.dismiss();
        if(this.badge ==1){
          await this.confirm('Sumaste + '+this.postPoint +' puntos'+ ' y '+this.msg);
        }else{
          await this.confirm('Sumaste + '+this.postPoint +' puntos' );
        }
      }
      this.key++;
    });
    
    
    
  }
  deleteImage(imgEntry, position) {
    const imagenesTemp = [...this.imagenes];
    imagenesTemp.splice(position, 1);
    let filtered = imagenesTemp.filter(name => name != imgEntry.name);
      var correctPath = imgEntry.filePath.substr(0, imgEntry.filePath.lastIndexOf('/') + 1);
      this.imagenes = [...filtered];
      this.file.removeFile(correctPath, imgEntry.name).then(res => {
          this.presentToast('Imagen eliminada.');
      });
  }
}
