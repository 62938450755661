import { Component, OnInit } from '@angular/core';
import { advertisement, ServiceService } from '../../service.service';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { LoadingController, Platform } from '@ionic/angular';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-codigoDesbloqueo',
  templateUrl: './codigoDesbloqueo.page.html',
  styleUrls: ['./codigoDesbloqueo.page.scss', '../../../assets/scss/styles.scss'],
})
export class CodigoDesbloqueoPage implements OnInit {

  hidden = true;
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();

  constructor(
    public services: ServiceService,
    private fileOpener: FileOpener,
    private file: File,
    private platform: Platform,
    private loadingController: LoadingController,
    private firebaseAnalytics: FirebaseAnalytics,
    private oneSignal: OneSignal
    ) {
      this.firebaseAnalytics.logEvent;
      this.firebaseAnalytics.setCurrentScreen("Código de desbloqueo");
      this.initializeApp();
    }

  async ngOnInit() {
    this.isMovil = this.services.isMovil();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(4,1);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  haasMachines(){
    window.open('https://tests-qa-hitec.s3.amazonaws.com/Documents/Alarma+144+temporal+y+definitiva+NGC.pdf', '_system', 'location=yes'); return false;

    // var ifrm = document.createElement('iframe');
    // ifrm.setAttribute('id', 'ifrm'); // assign an id

    // // to place before another page element
    // var el = document.getElementById('iframeHolder');
    // el.parentNode.insertBefore(ifrm, el);

    // // assign url
    // ifrm.setAttribute('src', 'http://kodezilla.com/hi-tec/desbloqueo-viewer.html');
    // ifrm.setAttribute('width', '0px');
    // ifrm.setAttribute('height', '0px');
  }

  othersMachines(){
    window.open('https://tests-qa-hitec.s3.amazonaws.com/Documents/procedimiento+clave+mensual+CHMER.pdf', '_system', 'location=yes'); return false;

    // var ifrm = document.createElement('iframe');
    // ifrm.setAttribute('id', 'ifrm'); // assign an id

    // // to place before another page element
    // var el = document.getElementById('iframeHolder');
    // el.parentNode.insertBefore(ifrm, el);

    // // assign url
    // ifrm.setAttribute('src', 'http://kodezilla.com/hi-tec/desbloqueo-viewer2.html');
    // ifrm.setAttribute('width', '0px');
    // ifrm.setAttribute('height', '0px');
  }

  initializeApp() {
    this.platform.ready().then(()=> {
      this.setupPush();
    });
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('ac5cca1e-1623-4144-95c2-b481605564ea', '576684940504');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      //this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      //this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
    this.oneSignal.sendTag("user_type","Código de Desbloqueo");
    this.oneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
    this.oneSignal.endInit();

  }
}
