import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { advertisement, ServiceService } from '../service.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { ModalcalenderPage } from '../modalcalender/modalcalender.page';

import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.page.html',
  styleUrls: ['./cursos.page.scss' , '../../assets/scss/styles.scss'],
})

export class CursosPage implements OnInit {
  datos;
  search: string;
  isMovil:boolean = false;
  advertisements:advertisement = new advertisement();
  @ViewChild('contentCenter') contentCenter: ElementRef;

  constructor(
    public services: ServiceService,
    public storage: Storage,
    private router: Router,
    public modalController: ModalController,
    private firebaseAnalytics: FirebaseAnalytics,
    private loadingController: LoadingController
    ) { 
      this.firebaseAnalytics.logEvent;
      this.firebaseAnalytics.setCurrentScreen("Cursos");
    }

  async ngOnInit() {
    //this.bringCoursesInformation();
    this.isMovil = this.services.isMovil();
    this.getCoursesCategory();
    if(!this.isMovil){
      this.advertisements = await this.services.getAdvertisementByScreen(6);
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,this.advertisements.Rows);
      this.advertisements.Random = this.services.NumberRandom(this.advertisements.Principal.length);
      this.services.postAdvertisementUser(this.advertisements.getAllRelAdsMetricsIdDisplayed());
    }
  }

  async scrollingAds(event:any) {
    let rows = Math.floor(Number(event.detail.scrollTop-this.advertisements.SizePage)/this.advertisements.SizeDivImage);
    let final = rows+this.advertisements.Rows;
    if(event.detail.scrollTop > this.advertisements.SizePage && rows>0 && final > this.advertisements.Displayed.length && this.advertisements.Displayed.length < this.advertisements.MediaBasico.length && event.detail.scrollTop < (this.contentCenter.nativeElement.clientHeight-this.advertisements.SizePage)){
      let displayedLength = this.advertisements.Displayed.length;
      this.advertisements.Displayed = this.advertisements.MediaBasico.slice(0,final);
      let disply = this.advertisements.Displayed.slice(displayedLength,this.advertisements.Displayed.length);
      this.services.postAdvertisementUser(this.advertisements.getRelAdsMetricsId(disply));
    }
  }

  ionViewWillEnter() {
    //this.bringCoursesInformation();
    this.getCoursesCategory();
  }

  public bringCoursesInformation(){
    this.services.courses().subscribe(
      data => {
        // console.log(data);
        this.datos = data;
      },
        error => {
        console.log(error);
      }
    );
  }

  public async  getCoursesCategory(){
    const loading = await this.loadingController.create({
      message: 'Cargando categorías...',
      duration: 3000,
    });

    loading.present();

    this.services.getCoursesCategories().then(
      (response : any ) => {
        this.loadingController.dismiss();
        if(response != null){
          this.datos = response;
          let data:any[] = [];
          this.datos.forEach((c:any) => {
            if (c.id >= 4) {//Sólo tomamos las categorías con id >= 4
              data.push(c);
            }
          });
          this.datos = data;
        }
      }).catch(
        (error: any) => {
          this.loadingController.dismiss()
          console.log(error);
          this.datos = new Array();
      });
  }

  sendId(idCourse){
    this.router.navigate(['/inscripcion', idCourse]);
  }

  sendToCourseDetails(idCourse){
    this.router.navigate(['/detallescourses', idCourse]);
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: ModalcalenderPage,
      // componentProps: { notifications: this.arrayNotification }
    });

    modal.onDidDismiss().then(data => {
      // this.bringUserInformation();
    });
    
    return await modal.present();
  }

}
